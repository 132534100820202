<div class="GridDiv">
  <mat-label class="title"></mat-label>
  @if (!isHandset()) {
    <div
      class="container"
      appDragAndDrop
      (fileDropped)="prepareFilesList($event)"
    >
      <input
        type="file"
        #fileDropRef
        id="fileDropRef"
        multiple
        (change)="prepareFilesList($event.target.files)"
        [disabled]="ITEMID === 'new'"
      />
      <mat-icon class="icon-display">upload_file</mat-icon>
      <h3>{{ 'Generic.dragAndDropFiles' | translate }}</h3>
      <h3>{{ 'Generic.or' | translate }}</h3>
      <label for="fileDropRef">{{
        'Generic.browseForFiles' | translate
      }}</label>
    </div>
  } @else {
    <div class="mobileUploadArea">
      <input
        hidden
        type="file"
        #fileDropRef
        multiple
        (change)="prepareFilesList($event.target.files)"
      />
      <button
        mat-raised-button
        color="primary"
        aria-label="file browse button"
        (click)="fileDropRef.click()"
      >
        {{ 'Generic.browseForFiles' | translate }}
      </button>
    </div>
  }
  <div class="files-list">
    @if (uploadedFiles().length > 1) {
      <mat-checkbox
        color="primary"
        [checked]="fullySelected()"
        [indeterminate]="partiallySelected()"
        (change)="updateFilesForDownload($event.checked)"
      >
        {{ 'Generic.selectAll' | translate }}
      </mat-checkbox>
      <br />
    }
    @if (uploadedFiles().length === 0) {
      <div class="infobox">
        {{ 'Generic.noSavedFiles' | translate }}
      </div>
    }
    @for (file of uploadedFiles(); track file.id) {
      <div class="single-file">
        @if (uploadedFiles().length > 1) {
          <mat-checkbox
            color="primary"
            [checked]="file.selected"
            (change)="updateFilesForDownload($event.checked, file.id)"
          >
          </mat-checkbox>
        }
        <mat-icon
          aria-hidden="false"
          aria-label="File Icon"
          fontIcon="description"
          class="file-icon"
        ></mat-icon>
        <div class="info">
          <h4 class="name">
            {{ file.fileName }}
          </h4>
          <p class="size">
            {{ file.fileSize | filesize }}
          </p>
        </div>
        <button
          mat-icon-button
          color="primary"
          aria-label="download button"
          (click)="downloadFile(file.id, file.fileName)"
        >
          <mat-icon>download</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          aria-label="delete button"
          (click)="deleteFile(file.id)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    }
    @if (uploadedFiles().length > 1) {
      <div style="display: flex; justify-content: center">
        <button
          mat-flat-button
          color="primary"
          [disabled]="numberOfSelected() < 2"
          (click)="downloadSelectedFiles()"
        >
          {{ 'Generic.downloadSelectedFiles' | translate }}
          <span>({{ numberOfSelected() }})</span>
        </button>
      </div>
    }
  </div>
</div>
