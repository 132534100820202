<div class="buttonPanel mat-elevation-z3">
  <button mat-button (click)="exit()">
    <mat-icon>keyboard_backspace</mat-icon>
    <span *ngIf="!isHandset()">{{ 'Generic.back' | translate }} </span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button mat-button (click)="changePass()">
    <mat-icon>lock</mat-icon>
    <span *ngIf="!isHandset()">{{ 'LocalAdmin.changePass' | translate }} </span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button mat-button (click)="save()">
    <mat-icon>save</mat-icon>
    <span *ngIf="!isHandset()">{{ 'Generic.save' | translate }} </span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
</div>

<div class="flexWrap">
  <app-user-form
    *ngIf="user"
    [ngClass]="{
      userFormCard: !isHandset(),
      'userFormCard-M': isHandset()
    }"
    [company]="null"
    [user]="user"
    [editMode]="true"
  ></app-user-form>

  <app-edit-avatar
    *ngIf="user.id"
    [user]="user"
    [ngClass]="{
      userFormCard: !isHandset(),
      'userFormCard-M': isHandset()
    }"
  ></app-edit-avatar>
</div>
