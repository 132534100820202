<div class="mainArea" [hidden]="showItem">
  <div class="buttonPanel mat-elevation-z3">
    <button mat-button (click)="goBack()">
      <mat-icon>keyboard_backspace</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.back' | translate }}</span>
    </button>
    <mat-divider [vertical]="true"></mat-divider>

    <span *ngIf="filterMode">
      <button mat-button (click)="getOnlyFollow()">
        <mat-icon>notification_important</mat-icon>
        <span *ngIf="!isHandset()">{{
          'Generic.onlyFollowFilter' | translate
        }}</span>
      </button>
    </span>
    <mat-divider [vertical]="true"></mat-divider>

    <span *ngIf="filterMode">
      <button mat-button (click)="search()">
        <mat-icon>search</mat-icon>
        <span *ngIf="!isHandset()">{{ 'Generic.search' | translate }}</span>
      </button>
    </span>
    <mat-divider [vertical]="true"></mat-divider>

    <span>
      <button mat-button (click)="clearAllfilters()">
        <mat-icon>clear</mat-icon>
        <span *ngIf="!isHandset()">{{
          'Generic.clearFilters' | translate
        }}</span>
      </button>
    </span>
    <mat-divider [vertical]="true"></mat-divider>
  </div>

  <div class="dataListArea">
    <app-data-list
      #filter
      *ngIf="entity_code"
      [ENTITY]="entity_code"
      (selectEntityItem)="selectItem($event)"
      [SHOWSEARCH]="false"
      [SHOWTRASHFILTER]="false"
      [FILTERMODE]="filterMode"
      [MULTISELECT]="true"
    ></app-data-list>
  </div>
</div>

<app-data-item
  *ngIf="showItem"
  [ITEMIDCOMP]="selectedItem"
  [ENTITYCOMP]="entity_code"
  [SHOWTOOLBAR]="true"
  (BACK)="closeItem()"
  [ADDBUTTONS]="ADDITEMBUTTONS"
  (ADDITIONALBTNACTION)="additionalItemAction($event)"
></app-data-item>

<button
  *ngIf="!showItem"
  mat-fab
  color="primary"
  class="add-button"
  matTooltip="{{ 'Generic.add' | translate }}"
  (click)="newItem()"
>
  <i class="material-icons">add</i>
</button>
