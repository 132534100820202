<div class="buttonPanel mat-elevation-z3">
  <button mat-button (click)="goBack()">
    <mat-icon>keyboard_backspace</mat-icon>
    <span *ngIf="!isHandset()">{{ 'Generic.back' | translate }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
</div>

<div [class.mainArea]="!isHandset()" [class.flex]="!isHandset()">
  <div [class.cards]="!isHandset()" [class.cardsM]="isHandset()">
    <app-approval-timeline
      [ASSIGNEES]="approval.assignees"
      *ngIf="approval"
    ></app-approval-timeline>
  </div>

  <mat-card
    class="example-card cards"
    *ngIf="approval"
    [class.cards]="!isHandset()"
    [class.cardsM]="isHandset()"
  >
    <mat-card-title-group>
      <mat-card-title class="flex">
        <app-show-avatar
          [SHOWCONNECTIVITY]="true"
          [USER]="approval.applicant"
        ></app-show-avatar>
        <span class="aplicantArea"
          >{{ approval.applicant.firstname }}
          {{ approval.applicant.lastname }}</span
        >
      </mat-card-title>
      <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-title-group>
    <mat-card-content>
      <table class="tableData">
        <tr>
          <th class="thStyle">
            <span>{{ 'Approvals.request' | translate }}:</span>
          </th>
        </tr>

        <tr>
          <td ItemLink>
            <p ItemLink>
              <span>{{ approval.message }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="2"><mat-divider></mat-divider><br /><Br /></td>
        </tr>

        <tr>
          <th class="thStyle" style="width: 50%">
            {{ 'Approvals.entityItem' | translate }}
          </th>
          <th class="thStyle">{{ 'Approvals.applyDate' | translate }}:</th>
        </tr>

        <tr>
          <td>
            <div *ngIf="approval.entdata_id">
              <span>#{{ approval.entdata_id }}</span>
              {{ approval.entdata_label }}
              <button mat-icon-button color="primary" (click)="showItem()">
                <mat-icon>visibility</mat-icon>
              </button>
            </div>
          </td>
          <td>{{ approval.created_at | date: 'y-M-d HH:mm' }}</td>
        </tr>
        <tr>
          <td colspan="2"><mat-divider></mat-divider><br /><Br /></td>
        </tr>

        <tr>
          <th colspan="2" class="thStyle">
            {{ 'Approvals.decisionNotes' | translate }}
          </th>
        </tr>

        <tr>
          <td colspan="2">
            <mat-form-field class="signatoryMessage">
              <textarea
                matInput
                [disabled]="!onDecisionMode"
                [(ngModel)]="desicionMessage"
              ></textarea>
            </mat-form-field>
          </td>
        </tr>
      </table>

      <div class="decisionBtnDiv" *ngIf="onDecisionMode">
        <button
          mat-flat-button
          color="warn"
          style="width: 140px"
          (click)="reject()"
        >
          <mat-icon>thumb_down_alt</mat-icon>
          <span *ngIf="!isHandset()">{{
            'Approvals.rejection' | translate
          }}</span>
        </button>
        <button
          mat-flat-button
          color="primary"
          style="width: 140px"
          (click)="approve()"
        >
          <span *ngIf="!isHandset()">{{
            'Approvals.approval' | translate
          }}</span>
          <mat-icon>thumb_up_alt</mat-icon>
        </button>
        <button
          mat-flat-button
          color="primary"
          style="width: 240px"
          (click)="approveAndFrw()"
        >
          <span *ngIf="!isHandset()">
            {{ 'Approvals.approveAndFrw' | translate }}</span
          >
          <mat-icon>thumb_up_alt</mat-icon>
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
