import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GoogleChartInterface } from 'ng2-google-charts';
import { DataItemDiagComponent } from 'src/app/common/entity/data-item-diag/data-item-diag.component';
import { ProductionLine, Sprint } from 'src/app/model';
import { ProjectService } from 'src/app/services/project.service';
import { LayoutService } from '../../../../../../services/layout.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-prod-schedule-sprint',
  templateUrl: './prod-schedule-sprint.component.html',
  styleUrls: ['./prod-schedule-sprint.component.css'],
})
export class ProdScheduleSprintComponent implements OnInit {
  @Input() SPRINT: Sprint;
  @Input() SPRINTS: Sprint[] = [];
  @Input() SCHEDULEID: number = 0;
  @Output() refreshData = new EventEmitter();
  public burnDownChartExpand: boolean = false;
  public chart: GoogleChartInterface;
  dragedOrder: any;
  isHandset = this.layoutService.isHandset;

  constructor(
    public dialog: MatDialog,
    private readonly projectService: ProjectService,
    private readonly layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.chart = {
      chartType: 'ColumnChart',
      dataTable: this.fillChart(),
      firstRowIsData: false,
      options: {
        chart: {},
        vAxis: { title: 'Hours', format: 'short' },

        //   hAxis: { title: 'Days from Starting Date', format: 'date' },
        seriesType: 'bars',
        //series: { 0: { type: 'line' } },
        height: 200,
        width: 500,
      },
    };
  }

  private getProductionLines() {
    const lines: ProductionLine[] = [];
    if (this.SPRINT.orders !== undefined) {
      for (const order of this.SPRINT.orders) {
        const lineId = '#' + (this.getInstallationFld(order)?.value ?? null);
        const time = this.getTime(order);

        const index = lines.findIndex((x) => x.production_line_id == lineId);
        if (index > -1) {
          lines[index].time += +time;
        } else {
          const newLine = new ProductionLine();
          newLine.production_line_id = lineId;
          newLine.name = order.installationName;
          newLine.time = +time;
          lines.push(newLine);
        }
      }
    }

    return lines;
  }

  private fillChart(): any[] {
    let arr: any[] = [['Product Line', 'Time']];
    let lines = this.getProductionLines();

    for (let i = 0; i < lines.length; i++) {
      arr.push([lines[i].name, lines[i].time]);
    }

    if (lines.length == 0) {
      arr.push(['-', 0]);
    }

    return arr;
  }

  public openTask(order: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: order.id,
      entityCode: 'productionorder',
    };
    this.dialog
      .open(DataItemDiagComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        this.refreshData.emit();
      });
  }

  public getQty(order: any): any {
    let index = order.fields.findIndex(
      (x) => x.field.fld_code == 'productionorder_normalizedqty'
    );

    return order.fields[index]?.value ?? '';
  }

  public getStatus(order: any): any {
    let index = order.fields.findIndex(
      (x) => x.field.fld_code == 'productionorder_status'
    );

    return order.fields[index]?.value ?? '';
  }

  public getInstallationFld(order: any): any {
    let index = order.fields.findIndex(
      (x) => x.field.fld_code == 'productionorder_productionline'
    );
    order.fields[index].for_entity = 'installations';
    return order.fields[index];
  }

  public getTime(order: any): any {
    let index = order.fields.findIndex(
      (x) => x.field.fld_code == 'productionorder_total_time'
    );

    return order.fields[index]?.value ?? 0;
  }

  public getMonth(order: any): any {
    let index = order.fields.findIndex(
      (x) => x.field.fld_code == 'productionorder_month'
    );

    return order.fields[index]?.value ?? '';
  }

  public dragstart(ev, order) {
    this.dragedOrder = order;

    ev.dataTransfer.setData('text', JSON.stringify(this.dragedOrder));
  }

  public dragend() {
    this.dragedOrder = null;
  }

  public moveOrderToSprint(sprint: Sprint, order) {
    this.projectService
      .addTaskToSprint(this.SCHEDULEID, sprint, order)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.refreshData.emit();
        }
      });
  }
}
