<div class="dashboardDiv">
  <ng-template
    #dashboardCard
    let-headerIcon="headerIcon"
    let-title="title"
    let-category="category"
    let-action="action"
    let-actionIcon="actionIcon"
    let-actionTooltip="actionTooltip"
    let-action2="action2"
    let-actionIcon2="actionIcon2"
    let-action2Tooltip="action2Tooltip"
  >
    <div class="dashboard-card">
      <div class="card-header" *ngIf="!isHandset()">
        <i class="material-icons">{{ headerIcon }}</i>
      </div>
      <div class="card-header" *ngIf="isHandset()">
        <button
          mat-fab
          color="primary"
          class="mobile-button"
          aria-label="Example icon button with a delete icon"
          *ngIf="action != null && action != 'new_approval'"
          (click)="act(action)"
        >
          <mat-icon>{{ headerIcon }}</mat-icon>
        </button>
        <button
          mat-fab
          color="primary"
          class="mobile-button"
          aria-label="Example icon button with a delete icon"
          *ngIf="action2 != null"
          routerLink="{{ action2 }}"
        >
          <mat-icon>{{ headerIcon }}</mat-icon>
        </button>
      </div>
      <div class="card-content">
        <p class="category">{{ category }}</p>
        <p class="title">{{ title }}</p>
      </div>
      <div class="card-actions">
        <button
          *ngIf="action != null"
          mat-raised-button
          color="primary"
          matTooltip="{{ actionTooltip }}"
          (click)="act(action)"
        >
          <mat-icon>{{ actionIcon }}</mat-icon></button
        >&nbsp;
        <button
          *ngIf="action2 != null"
          mat-raised-button
          color="primary"
          matTooltip="{{ action2Tooltip }}"
          (click)="redirect(action2)"
        >
          <mat-icon>{{ actionIcon2 }}</mat-icon>
        </button>
      </div>
    </div>
  </ng-template>

  <p *ngIf="isSalesman" class="salesman-title">Η εβδομάδα μου</p>
  <br />
  <mat-grid-list [cols]="dashboardCols" [rowHeight]="dashboardRowHeight">
    <mat-grid-tile *ngIf="!isSalesman">
      <ng-container
        [ngTemplateOutlet]="dashboardCard"
        [ngTemplateOutletContext]="{
          title: (webSocketService.notifications$ | async).length,
          headerIcon: 'notifications',
          category: translateService.instant('Generic.notifications'),
          action: 'notifications',
          actionIcon: 'view_list',
          actionTooltip: translateService.instant('Generic.display'),
          action2: null,
          actionIcon2: null,
          action2Tooltip: null
        }"
      >
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile>
      <ng-container
        [ngTemplateOutlet]="dashboardCard"
        [ngTemplateOutletContext]="{
          title: dashboardNums.approvals,
          headerIcon: 'check',
          category: translateService.instant('Generic.approvals'),
          action: 'new_approval',
          actionIcon: 'add_box',
          actionTooltip: translateService.instant('Generic.add'),
          action2: '/approval',
          actionIcon2: 'view_list',
          action2Tooltip: translateService.instant('Generic.display')
        }"
      >
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="!isSalesman">
      <ng-container
        [ngTemplateOutlet]="dashboardCard"
        [ngTemplateOutletContext]="{
          title: (webSocketService.questions$ | async).length,
          headerIcon: 'help',
          category: translateService.instant('Generic.questions'),
          action: 'questions',
          actionIcon: 'view_list',
          actionTooltip: translateService.instant('Generic.display'),
          action2: null,
          actionIcon2: null,
          action2Tooltip: null
        }"
      >
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="!isSalesman">
      <ng-container
        [ngTemplateOutlet]="dashboardCard"
        [ngTemplateOutletContext]="{
          title: dashboardNums.meetings,
          headerIcon: 'diversity_3',
          category: translateService.instant('Modules.meetings'),
          action: null,
          actionIcon: null,
          actionTooltip: null,
          action2: '/meetings',
          actionIcon2: 'view_list',
          action2Tooltip: translateService.instant('Generic.display')
        }"
      >
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="!isSalesman">
      <ng-container
        [ngTemplateOutlet]="dashboardCard"
        [ngTemplateOutletContext]="{
          title: dashboardNums.channelMsg,
          headerIcon: 'hub',
          category: translateService.instant('Generic.channelMessages'),
          action: null,
          actionIcon: null,
          actionTooltip: null,
          action2: '/channel',
          actionIcon2: 'view_list',
          action2Tooltip: translateService.instant('Generic.display')
        }"
      >
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile>
      <ng-container
        [ngTemplateOutlet]="dashboardCard"
        [ngTemplateOutletContext]="{
          title: dashboardNums.tasks,
          headerIcon: 'playlist_add_check',
          category: translateService.instant('Modules.task'),
          action: null,
          actionIcon: null,
          actionTooltip: null,
          action2: '/task',
          actionIcon2: 'view_list',
          action2Tooltip: translateService.instant('Generic.display')
        }"
      >
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="isSalesman">
      <ng-container
        [ngTemplateOutlet]="dashboardCard"
        [ngTemplateOutletContext]="{
          title: dashboardNums.quotes,
          headerIcon: 'production_quantity_limits',
          category: translateService.instant('Modules.quote'),
          action: null,
          actionIcon: null,
          actionTooltip: null,
          action2: '/quote',
          actionIcon2: 'view_list',
          action2Tooltip: translateService.instant('Generic.display')
        }"
      >
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="isSalesman">
      <ng-container
        [ngTemplateOutlet]="dashboardCard"
        [ngTemplateOutletContext]="{
          title: dashboardNums.opportunities,
          headerIcon: 'military_tech',
          category: translateService.instant('Modules.opportunity'),
          action: null,
          actionIcon: null,
          actionTooltip: null,
          action2: '/opportunity',
          actionIcon2: 'view_list',
          action2Tooltip: translateService.instant('Generic.display')
        }"
      >
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="isSalesman">
      <ng-container
        [ngTemplateOutlet]="dashboardCard"
        [ngTemplateOutletContext]="{
          title: dashboardNums.serviceTickets,
          headerIcon: 'support_agent',
          category: translateService.instant('Modules.service'),
          action: null,
          actionIcon: null,
          actionTooltip: null,
          action2: '/service',
          actionIcon2: 'view_list',
          action2Tooltip: translateService.instant('Generic.display')
        }"
      >
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="isSalesman">
      <ng-container
        [ngTemplateOutlet]="dashboardCard"
        [ngTemplateOutletContext]="{
          title: dashboardNums.invoices,
          headerIcon: 'payments',
          category: translateService.instant('Modules.invoice'),
          action: null,
          actionIcon: null,
          actionTooltip: null,
          action2: '/invoice',
          actionIcon2: 'view_list',
          action2Tooltip: translateService.instant('Generic.display')
        }"
      >
      </ng-container>
    </mat-grid-tile>
  </mat-grid-list>
</div>
