@if (isHandset()) {
  <section class="baseFilters-M">
    <mat-form-field class="fullwidth">
      <mat-label>{{ 'Generic.savedFilters' | translate }}</mat-label>
      <mat-select (selectionChange)="applySavedFilter($event.value)">
        <mat-option [value]="null"> - </mat-option>
        @for (savedFilter of savedFilters(); track savedFilter.id) {
          <mat-option [value]="savedFilter">
            {{ savedFilter.title }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </section>
} @else {
  <mat-card class="savedFiltersCard" style="height: 100%">
    <mat-form-field>
      <mat-label>{{ 'Generic.filterName' | translate }} </mat-label>
      <input matInput type="text" [(ngModel)]="newFilterName" />
    </mat-form-field>
    <button mat-icon-button (click)="onSaveFilters()">
      <mat-icon color="primary">save</mat-icon>
    </button>

    <div mat-subheader>{{ 'Generic.filterList' | translate }}</div>
    <mat-list class="saved-filters-list">
      @for (
        savedFilter of savedFilters();
        let i = $index;
        track savedFilter.id
      ) {
        <mat-list-item
          class="savedFilter"
          (click)="applySavedFilter(savedFilter)"
        >
          <div matListItemTitle>{{ i + 1 }}) {{ savedFilter.title }}</div>
          <button
            mat-icon-button
            matListItemMeta
            (click)="deleteFilter(savedFilter, $event)"
          >
            <mat-icon color="warn">clear</mat-icon>
          </button>
        </mat-list-item>
      }
    </mat-list>
  </mat-card>
}
