import { Component, DestroyRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GoogleChartInterface, GoogleChartComponent } from 'ng2-google-charts';
import { DataItemDiagComponent } from 'src/app/common/entity/data-item-diag/data-item-diag.component';
import { DurationHelper } from 'src/app/common/helper';
import { TaskDto } from 'src/app/model';
import { TaskService } from '../../../../../services/task.service';
import { LayoutService } from '../../../../../services/layout.service';

@Component({
  selector: 'app-gannt-chart',
  templateUrl: './gannt-chart.component.html',
  styleUrls: ['./gannt-chart.component.css'],
})
export class GanntChartComponent implements OnInit {
  constructor(
    public readonly dialog: MatDialog,
    private readonly taskService: TaskService,
    private readonly layoutService: LayoutService,
    private readonly destroyRef: DestroyRef
  ) {}

  @Input() ITEMID: any;
  @Input() ITEM: any;
  @ViewChild('gantt', { static: false }) ganntChart: GoogleChartComponent;
  tasks: TaskDto[] = [];
  public showChart = false;
  public chart: GoogleChartInterface;
  isHandset = this.layoutService.isHandset;

  ngOnInit() {
    this.showChart = false;

    this.taskService.projectSprints.subscribe((sprints) => {
      this.tasks = [];
      for (const sprint of sprints) {
        for (const task of sprint.tasksDto) {
          task.sprint = sprint.name;
          this.tasks.push(task);
        }
      }
      this.chart = {
        chartType: 'Gantt',
        dataTable: this.fillGantt(this.tasks),
        firstRowIsData: false,
        options: {
          height: window.innerHeight,
          width: window.innerWidth - 100,
          gantt: {
            trackHeight: 30,
          },
        },
      };
      if (this.ganntChart) {
        this.ganntChart.draw(this.chart);
      }

      this.showChart = true;
    });
  }

  daysToMilliseconds(duration: string) {
    const durationHelper: DurationHelper = new DurationHelper();

    const addminutes = durationHelper.durationToMinutes24h(duration);

    if (addminutes < 1440) {
      return 1440;
    } else {
      return addminutes;
    }
  }

  private validateChildTask(childId: number): any {
    if (this.tasks.findIndex((x) => x.id == childId) >= 0) {
      return childId + '';
    } else {
      return null;
    }
  }

  public fillGantt(tasks: TaskDto[]): any[] {
    const arr: any[] = [];
    for (const task of tasks) {
      if (task.task_startdate && task.task_duration) {
        const dateStart = new Date(task.task_startdate);
        const enddate: Date = new Date(task.task_startdate);
        enddate.setMinutes(
          enddate.getMinutes() + this.daysToMilliseconds(task.task_duration)
        );
        let percentComplete = 0;
        if (task.task_status === 'Done') {
          percentComplete = 100;
        }

        let taskDesc = '#' + task.id + ' ' + task.task_desc;
        taskDesc =
          this.isHandset() && taskDesc.length > 20
            ? taskDesc.substring(0, 20) + '...'
            : taskDesc.substring(0, 35) + '...';
        arr.push([
          task.id + '',
          taskDesc,
          task.sprint ? task.sprint : 'Backlog',
          new Date(
            dateStart.getFullYear(),
            dateStart.getMonth(),
            dateStart.getDate()
          ),
          new Date(
            enddate.getFullYear(),
            enddate.getMonth(),
            enddate.getDate()
          ),
          0,
          percentComplete,
          this.validateChildTask(task.task_childtask),
        ]);
      }
    }

    if (arr.length > 0) {
      this.showChart = true;
      arr.unshift([
        'Task ID',
        'Task Name',
        'Resource',
        'Start Date',
        'End Date',
        'Duration',
        'Percent Complete',
        'Dependencies',
      ]);
    }
    return arr;
  }

  public openTask(ev) {
    if (ev.message === 'select') {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        itemId: ev.selectedRowFormattedValues[0],
        entityCode: 'tasks',
        // sourceItemId: null
      };

      const dialogRef = this.dialog.open(DataItemDiagComponent, dialogConfig);

      dialogRef.afterClosed().subscribe((result: any) => {
        this.reloadChart();
      });
    }
  }

  public reloadChart() {
    this.showChart = false;
    this.taskService.refreshProjectData.next(true);
  }
}
