import { CompanyService } from '../../../services/company.service';
import { EventEmitter, ViewChild } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Entconnection,
  Entdata,
  PagedData,
  Entity,
  Department,
  BulkItemsBatch,
  ListEntdata,
} from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { EntityItemFilter } from '../../filters';
import { DataListFiltersComponent } from './data-list-filters/data-list-filters.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataBulkChangesComponent } from '../data-bulk-changes/data-bulk-changes.component';
import { AfterSaveEnityItemService } from 'src/app/services/after-save-enity-item.service';
import { TransformationHelper } from '../../helper';
import { TransformEntityToErpComponent } from '../../erp/transformation/transform-entity-to-erp/transform-entity-to-erp.component';
import { ElementDataService } from 'src/app/services/elementdata.service';
import { DashboardService } from '../../../services/dashboard.service';
import { TaskService } from '../../../services/task.service';
import { LayoutService } from '../../../services/layout.service';

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.css'],
})
export class DataListComponent implements OnInit {
  public entityObj: PagedData<any> = null;
  public entity: Entity;

  public gridColumns: any[] = [];
  public departments: Department[] = [];
  public canTransformToErp = false;

  @Input() ENTITY: string;
  @Input() SHOWSEARCH = false;
  @Input() SHOWTRASHFILTER = false;
  @Input() SEARCHFIELDS: any[];
  @Input() FILTERFORID: string;
  @Input() FILTERMODE = false;
  @Input() MULTISELECT = false;
  @Input() SHOWSPINNER = true;
  @Input() SEARCHONCOLS = false;
  @Input() entityFilters: EntityItemFilter = new EntityItemFilter();
  @Output() selectEntityItem = new EventEmitter();
  @ViewChild(DataListFiltersComponent) filtersComp: DataListFiltersComponent;
  pageSizeOptions: number[] = [20, 50, 100];
  isHandset = this.layoutService.isHandset;

  constructor(
    private readonly entityService: EntityService,
    private readonly translateService: TranslateService,
    private readonly userService: UserService,
    private readonly loginService: LoginService,
    private readonly notificationService: NotificationService,
    private readonly companyService: CompanyService,
    private readonly breakpointObserver: BreakpointObserver,
    public readonly dialog: MatDialog,
    private readonly afterSaveEntityItemService: AfterSaveEnityItemService,
    private readonly elementDataService: ElementDataService,
    private readonly dashboardService: DashboardService,
    private readonly layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    if (this.FILTERFORID) {
      this.entityFilters.connectedWith = +this.FILTERFORID;
      this.entityFilters.trashed = 2;
    } else {
      this.entityFilters.trashed = 0;
    }

    if (this.SEARCHONCOLS) {
      this.pageSizeOptions = [20];
      this.entityFilters.pageSize = 20;
    }

    this.getColumns();
    this.getData();
    this.canTransformToErp = TransformationHelper.CanEntityTransformToErpHelper(
      this.ENTITY
    );
  }

  public getColumns() {
    if (this.ENTITY === 'user') {
      this.getUserGridColumns();
    } else {
      this.getGridColumns();
    }
  }

  public getData() {
    if (this.ENTITY === 'user') {
      this.getUserData();
      this.getDepartments();
    } else {
      // Check If there is an active salesman filter that needs to be applied first
      if (this.dashboardService.applySalesmanEntityDateFilter.valueOf()) {
        this.checkForSalesmanFilter();
      }
      this.getEntityList();
    }
  }

  private getUserData() {
    this.userService
      .getUsers(this.entityFilters)
      .pipe(take(1))
      .subscribe((resUsers) => {
        this.entityObj = resUsers;
      });
  }

  private getDepartments() {
    this.companyService
      .getCompanyForUser()
      .pipe(take(1))
      .subscribe((res) => {
        this.departments = res.departments;
      });
  }

  private getUserGridColumns() {
    this.gridColumns = [
      { title: '#', attr: 'id', view: true, mobileView: true, type: 'data' },
      {
        title: this.translateService.instant('Generic.avatar'),
        attr: 'avatar',
        view: true,
        mobileView: false,
        type: 'avatar',
      },
      {
        title: this.translateService.instant('Register.firstname'),
        attr: 'firstname',
        view: true,
        mobileView: true,
        type: 'data',
      },
      {
        title: this.translateService.instant('Register.lastname'),
        attr: 'lastname',
        view: true,
        mobileView: true,
        type: 'data',
      },
      {
        title: '@' + this.translateService.instant('Register.nickname'),
        attr: 'nickname',
        view: true,
        mobileView: false,
        type: 'data',
      },
      { title: 'Email', attr: 'email', view: true, type: 'data' },
      {
        title: this.translateService.instant('Register.secondEmail'),
        attr: 'email2',
        view: false,
        mobileView: false,
        type: 'data',
      },
      {
        title: this.translateService.instant('Register.phone1'),
        attr: 'phone1',
        view: false,
        mobileView: false,
        type: 'data',
      },
      {
        title: this.translateService.instant('Register.phone2'),
        attr: 'phone2',
        view: true,
        mobileView: false,
        type: 'data',
      },
      {
        title: this.translateService.instant('Company.department'),
        attr: 'title',
        view: true,
        mobileView: false,
        type: 'data',
      },
      {
        title: this.translateService.instant('Company.location'),
        attr: 'location',
        view: false,
        mobileView: false,
        type: 'data',
      },
      {
        title: this.translateService.instant('Register.birthdate'),
        attr: 'birthdate',
        view: false,
        mobileView: false,
        type: 'date',
      },
      {
        title: this.translateService.instant('Generic.created'),
        attr: 'created_at',
        view: true,
        mobileView: false,
        type: 'date',
      },
    ];
  }

  private createGridColums(data: Entity): any[] {
    const arr = [{ title: '#', attr: 'id', view: true, type: 'data' }];
    const fields = data.fields.filter((x) => x.isGridColumn === 1);

    const editBulkAction = {
      title: this.translateService.instant('Generic.edit'),
      attr: 'editBulkAction',
      view: false,
      mobileView: false,
      type: 'bulk_action',
      icon: 'edit',
      action: 'edit_bulk_action',
      color: 'primary',
    };
    arr.push(editBulkAction);

    if (this.canTransformToErp) {
      const tranformToErpBulkAction = {
        title: this.translateService.instant('Generic.transformation'),
        attr: 'tranformToErp',
        view: false,
        mobileView: false,
        type: 'bulk_action',
        icon: 'transform',
        action: 'tranform_to_erp_action',
        color: 'primary',
      };
      arr.push(tranformToErpBulkAction);
    }
    const activateBulkAction = {
      title: this.translateService.instant('Generic.deactivation'),
      attr: 'deactivateBulkAction',
      view: false,
      mobileView: false,
      type: 'bulk_action',
      icon: 'update_disabled',
      action: 'deactivate_bulk_action',
      color: 'warn',
    };
    arr.push(activateBulkAction);
    const deactivateBulkAction = {
      title: this.translateService.instant('Generic.activation'),
      attr: 'activateBulkAction',
      view: false,
      mobileView: false,
      type: 'bulk_action',
      icon: 'update',
      action: 'activate_bulk_action',
      color: 'primary',
    };
    arr.push(deactivateBulkAction);

    for (const col of fields) {
      const gridPreview = col.gridPreview === 1;
      const mobilePreview = col.mobilePreview === 1;
      const isSearchable = col.hiddeOnFilters === 0;

      if (col.field_specs.fld_code === 'pdfExport') {
        const pdfExportObj = {
          title: this.getDBFldTransateLabel(col),
          attr: col.fld_code,
          view: gridPreview,
          mobileView: mobilePreview,
          type: 'action',
          icon: 'save_alt',
          action: 'pdfExport',
          color: 'primary',
          fldId: col.id,
        };
        arr.push(pdfExportObj);
      } else {
        const fieldObj = {
          title: this.getDBFldTransateLabel(col),
          attr: col.fld_code,
          view: gridPreview,
          mobileView: mobilePreview,
          type: 'data',
          search: isSearchable,
          entityOrUser:
            col.field_specs.fld_code === 'singleEntity' ||
            col.field_specs.fld_code === 'user'
              ? col
              : null,
        };
        arr.push(fieldObj);
      }
    }
    const createdObj = {
      title: this.translateService.instant('Generic.created'),
      attr: 'created_at',
      view: true,
      mobileView: false,
      type: 'date',
    };
    arr.push(createdObj);

    if (this.FILTERFORID) {
      const unlinkObj = {
        title: this.translateService.instant('Generic.unlink'),
        attr: 'unlink',
        view: true,
        type: 'action',
        mobileView: true,
        icon: 'link_off',
        action: 'unlink',
        color: 'warn',
      };
      arr.push(unlinkObj);
    }

    if (
      data.fields.filter((x) => x.field_specs.fld_code === 'step').length > 0
    ) {
      const currentStepObj = {
        title: this.translateService.instant('Generic.currentStep'),
        attr: 'current_step',
        view: true,
        mobileView: false,
        type: 'data',
      };
      arr.push(currentStepObj);
    }

    return arr;
  }

  public onActionPress(data) {
    if (data.action === 'unlink') {
      this.unlinkEntityItem(data.item);
    } else if (data.action === 'pdfExport') {
      this.createPdf([data.item.id], data.fldId);
    }
  }

  public onBulkActionPress(data) {
    if (data.action === 'unlink') {
    } else if (data.action === 'pdfExport') {
      const selectedIds = [];
      for (const item of data.items) {
        selectedIds.push(item.id);
      }
      this.createPdf(selectedIds, data.fldId);
    } else if (data.action === 'edit_bulk_action') {
      const result = data.items.map((a) => a.id);
      this.bulk_edit_diag(result);
    } else if (data.action === 'deactivate_bulk_action') {
      const result = data.items.map((a) => a.id);
      this.bulk_deactivate(result);
    } else if (data.action === 'activate_bulk_action') {
      const result = data.items.map((a) => a.id);
      this.bulk_activate(result);
    } else if (data.action === 'tranform_to_erp_action') {
      const result = data.items.map((a) => a.id);
      this.bulk_transformToErp(result);
    }
  }

  public bulk_activate(ids: string[]) {
    this.entityService
      .bulk_activateItems(ids)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successΑctivate',
            'Ok',
            { duration: 3000 }
          );
          this.getData();
        } else {
          this.notificationService.showSnackbarMessage(
            'Messages.failΑctivate',
            'Ok',
            { duration: 3000 }
          );
        }
      });
  }

  public bulk_deactivate(ids: string[]) {
    this.entityService
      .bulk_deactivateItems(ids)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successDeactivate',
            'Ok',
            { duration: 3000 }
          );
          this.getData();
        } else {
          this.notificationService.showSnackbarMessage(
            'Messages.failDeactivate',
            'Ok',
            { duration: 3000 }
          );
        }
      });
  }

  public bulk_transformToErp(ids: string[]) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entity_ids: ids,
    };

    const dialogRef = this.dialog.open(
      TransformEntityToErpComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
      }
    });
  }

  public bulk_edit_diag(itemsIds: string[]) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: this.ENTITY,
    };

    const dialogRef = this.dialog.open(DataBulkChangesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        const bulkItems: BulkItemsBatch = new BulkItemsBatch();
        bulkItems.items_ids = itemsIds;
        bulkItems.values = result;
        this.bulk_save(bulkItems);
      }
    });
  }

  private bulk_save(bulkItems: BulkItemsBatch) {
    this.entityService
      .bulkSaveItems(this.ENTITY, bulkItems)
      .pipe(take(1))
      .subscribe((res) => {
        if (res?.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successfulSave'
          );
          for (const resultItem of res.data) {
            this.afterSaveEntityItemService.specialLogicAfterSave(
              this.ENTITY,
              resultItem
            );
          }
          this.getData();
        }
      });
  }

  public getEntityList() {
    if (this.SEARCHFIELDS) {
      this.entityFilters.searchFields = this.SEARCHFIELDS;
    }
    if (this.SHOWSPINNER) {
      this.entityService
        .getEntityDataList(this.entityFilters, this.ENTITY)
        .pipe(take(1))
        .subscribe((dataRes) => {
          this.serializeData(dataRes);
          this.elementDataService.setExtractedData(dataRes.data);
          this.entityObj = dataRes;
          this.addCurrentStepValue(this.entityObj);
        });
    } else {
      this.entityService
        .getEntityDataListNoSpinner(this.entityFilters, this.ENTITY)
        .pipe(take(1))
        .subscribe((dataRes) => {
          this.serializeData(dataRes);
          this.elementDataService.setExtractedData(dataRes.data);
          this.entityObj = dataRes;
          this.addCurrentStepValue(this.entityObj);
        });
    }
  }

  private serializeData(dataRes: PagedData<ListEntdata>) {
    for (const entdata of dataRes.data) {
      for (const field of entdata.fields) {
        entdata[field.fieldCode] = field.value;
      }
      delete entdata.fields;
    }
  }

  private getGridColumns() {
    if (this.SHOWSPINNER) {
      this.entityService
        .getFldsEntityByName(this.ENTITY)
        .pipe(take(1))
        .subscribe((resFlds) => {
          this.entity = resFlds;
          this.gridColumns = this.createGridColums(resFlds);
        });
    } else {
      this.entityService
        .getFldsEntityByNameNoSpinner(this.ENTITY)
        .pipe(take(1))
        .subscribe((resFlds) => {
          this.entity = resFlds;
          this.gridColumns = this.createGridColums(resFlds);
        });
    }
  }

  public refreshEntityList(filter: EntityItemFilter | null) {
    if (filter) {
      this.entityFilters = filter;
      if (this.ENTITY !== 'user') {
        this.addFieldsWithValuesToFilters();
      }
    }
    this.getData();
  }

  public refreshEntityListFromEvent(filter: EntityItemFilter | null) {
    if (filter) {
      this.entityFilters = filter;
    }
    this.getData();
  }

  public onSelectEntityItem(entityItem: any) {
    this.selectEntityItem.emit(entityItem);
  }

  public getDBFldTransateLabel(field: any): string {
    const lang = this.loginService.getLoginUser()?.lang;
    return lang === 'en' ? field.label_en : field.label_gr;
  }

  public unlinkEntityItem(item: any) {
    const conToDel = new Entconnection();
    conToDel.entdata_id = +this.FILTERFORID;
    conToDel.with_entdata_ids = [item.id];
    this.entityService
      .removeEntConnection(conToDel)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successDisconnection',
            'Ok',
            { duration: 3000 }
          );
          this.getEntityList();
        } else {
          this.notificationService.showSnackbarMessage(
            'Messages.failDisconnection',
            'Ok',
            { duration: 3000 }
          );
        }
      });
  }

  private addFieldsWithValuesToFilters() {
    this.entityFilters.searchFields = [];
    for (const field of this.entity.fields) {
      if (
        (field.search_from && field.search_from !== '') ||
        (field.search_to && field.search_to !== '')
      ) {
        this.entityFilters.searchFields.push(field);
      }
    }
    const stepFields = this.entity.fields.filter(
      (x) => x.field_specs.fld_code === 'step'
    );
    if (stepFields.length > 0) {
      this.entityFilters.stepValue = this.getStepFromValue(
        this.filtersComp.fields.filter((x) => x.fld_code === 'current_step')[0]
          .search_from
      );
    }
  }

  public createPdf(itemIds: number[], fldId) {
    this.entityService
      .exportItemToPdf(itemIds, fldId)
      .pipe(take(1))
      .subscribe((file) => {
        if (file) {
          window.open(window.URL.createObjectURL(file));
        }
      });
  }

  public onColumnSearch(event) {
    const fld = this.entity.fields.find(
      (x) => x.fld_code === event.column.attr
    );
    fld.search_from = event.valueFrom;
    // If the value of the dropdown is not an array convert it to one
    if (
      fld.field_specs.fld_code === 'dropdown' &&
      !Array.isArray(event.valueFrom)
    ) {
      fld.search_from = [event.valueFrom];
    }
    if (fld.field_specs.fld_code === 'number' && event.valueTo === undefined) {
      fld.search_to = event.valueFrom;
    }
    if (event.valueTo) {
      fld.search_to = event.valueTo;
    }
    if (event.valueTo === null) {
      fld.search_to = null;
    }
    const searchIndex = this.entityFilters.searchFields.findIndex(
      (x) => x.id === fld.id
    );
    if (searchIndex === -1) {
      this.entityFilters.searchFields.push(fld);
    } else {
      if (event.valueFrom === '') {
        this.entityFilters.searchFields.splice(searchIndex, 1);
      } else {
        this.entityFilters.searchFields[searchIndex] = fld;
      }
    }
    if (event.getData) {
      this.getData();
    }
  }

  private addCurrentStepValue(dataRes: PagedData<any>) {
    if (this.gridColumns.find((x) => x.attr === 'current_step') !== undefined) {
      const stepFields = this.entity.fields.filter(
        (field) => field.field_specs.fld_code === 'step'
      );
      stepFields.sort((a, b) => a.id - b.id);
      dataRes.data.forEach((entdata) => {
        let completedSteps = 0;
        stepFields.forEach((stepField) => {
          if (entdata[stepField.fld_code] !== null) {
            completedSteps = completedSteps + 1;
          }
        });
        if (completedSteps === stepFields.length) {
          entdata.current_step = 'Closed';
        } else {
          entdata.current_step = this.getDBFldTransateLabel(
            stepFields[completedSteps]
          );
        }
      });
    }
  }

  private getStepFromValue(searchValue: string) {
    const entityStepFields = this.entity.fields.filter(
      (x) => x.field_specs.fld_code === 'step'
    );
    if (searchValue === 'Closed') {
      return entityStepFields.length;
    } else if (searchValue === undefined || searchValue === null) {
      return null;
    } else {
      return entityStepFields.findIndex(
        (step) => this.getDBFldTransateLabel(step) === searchValue
      );
    }
  }

  private checkForSalesmanFilter() {
    this.entityService.getEntityListWithSalesmanFilters
      .pipe(take(1))
      .subscribe((res) => {
        this.getEntityList();
        this.dashboardService.applySalesmanEntityDateFilter = false;
      });
  }

  resetFilters(event: { fetchData: boolean }) {
    this.entityFilters = new EntityItemFilter();
    if (event.fetchData) {
      this.getData();
    }
  }

  fillNonFieldFilterValues($event: any) {
    this.entityFilters.trashed = +$event.trashed;
    this.entityFilters.connectedWith = $event.connectedWith;
    this.entityFilters.ownerId = $event.ownerId;
    this.entityFilters.createdAtSearchDateStart =
      $event.createdAtSearchDateStart;
    this.entityFilters.createdAtSearchDateEnd = $event.createdAtSearchDateEnd;
    this.entityFilters.itemId = $event.itemId;
    this.entityFilters.strValue = $event.strValue;
  }
}
