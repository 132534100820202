import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-data-replicate',
  templateUrl: './data-replicate.component.html',
  styleUrls: ['./data-replicate.component.css'],
})
export class DataReplicateComponent {
  public amount: number = 1;

  constructor(
    public dialogRef: MatDialogRef<DataReplicateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
