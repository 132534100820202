import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Field } from '../model';

@Pipe({
  name: 'statisticsFunction',
  standalone: true,
})
export class StatisticsFunctionPipe implements PipeTransform {
  constructor(private translationService: TranslateService) {}

  transform(
    selected_function: string | null,
    control_field: Field | null,
    userLang: string
  ): string {
    if (selected_function === null) {
      return ' - ';
    } else {
      if (selected_function === 'count') {
        return this.translationService.instant(
          `Statistics.${selected_function}`
        );
      } else {
        return (
          this.translationService.instant(`Statistics.${selected_function}`) +
          (userLang === 'gr'
            ? ` ως προς ${control_field.label_gr}`
            : ` of ${control_field.label_en}`)
        );
      }
    }
  }
}
