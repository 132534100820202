<div style="display: flex">
  <mat-card style="width: 100%">
    <mat-card-subtitle> Tasklist </mat-card-subtitle>

    <app-task-list-table
      [TASKS]="SPRINT.tasksDto"
      (refreshData)="refreshData.emit()"
      (selectTask)="openTask($event)"
      [SHOWMOVESPRINT]="true"
      [SPRINTS]="ALLSPRINT"
      [PROJECTID]="PROJECTID"
    ></app-task-list-table>
  </mat-card>

  <mat-card class="burndownChartCard" *ngIf="!isHandset()">
    <mat-card-subtitle> {{ SPRINT.name }} Burndown Chart </mat-card-subtitle>
    <span>
      <google-chart [data]="chart"></google-chart
      ><!-- -->
    </span>
  </mat-card>
</div>

<mat-card
  style="width: 100%; overflow-x: auto; margin-top: 10px"
  *ngIf="isHandset()"
  (click)="burnDownChartExpand = !burnDownChartExpand"
>
  <span>{{ SPRINT.name }} Burndown Chart <mat-icon>query_stats</mat-icon></span>
  <span>
    <mat-icon class="arrowPos" *ngIf="!burnDownChartExpand"
      >expand_more</mat-icon
    >
    <mat-icon class="arrowPos" *ngIf="burnDownChartExpand"
      >expand_less</mat-icon
    ></span
  >
  <google-chart [data]="chart" *ngIf="burnDownChartExpand"></google-chart>
</mat-card>
