import {
  Component,
  Input,
  AfterViewChecked,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { ChatMsg, User } from 'src/app/model';
import { ChatService } from 'src/app/services/chat.service';
import { LoginService } from 'src/app/services/login.service';
import { WebsocketsService } from 'src/app/services/websockets.service';
import { LayoutService } from '../../../services/layout.service';

@Component({
  selector: 'app-chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.css'],
})
export class ChatRoomComponent implements OnChanges, AfterViewChecked {
  @Input() userToChat: User;
  @Input() isPopup: boolean = false;
  @ViewChild('msgList') private msgListElem: ElementRef;

  selectedConversation: boolean = true;
  text: string = '';
  chatHistory: ChatMsg[] = [];
  showLoader: boolean = false;
  isHandset = this.layoutService.isHandset;
  constructor(
    private readonly chatService: ChatService,
    private readonly loginService: LoginService,
    private readonly sockets: WebsocketsService,
    private readonly layoutService: LayoutService
  ) {}
  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getChatHistory();
  }

  ngOnInit(): void {
    this.sockets.subject.subscribe((newMsgLive) => {
      if (
        this.userToChat &&
        newMsgLive.chatMsg.user_from == this.userToChat.id
      ) {
        this.chatHistory.push(newMsgLive.chatMsg);
      }
    });
  }

  public isMessageMine(chat: ChatMsg): boolean {
    return chat.user_from == this.loginService.getLoginUser().id;
  }

  public getChatHistory() {
    if (this.userToChat) {
      this.showLoader = true;
      this.chatService
        .getChatHistory(this.userToChat)
        .pipe(take(1))
        .subscribe((historyRes) => {
          this.chatHistory = historyRes.data.reverse();
          this.showLoader = false;
          this.scrollToBottom();
        });
    }
  }

  public sendMessage() {
    if (this.text != '') {
      let newChatMsg: ChatMsg = new ChatMsg();
      newChatMsg.user_from = this.loginService.getLoginUser().id;
      newChatMsg.message = this.text;
      newChatMsg.created_at = new Date();
      newChatMsg.user_to = this.userToChat.id;
      this.chatHistory.push(newChatMsg);
      this.text = '';
      this.chatService
        .newChatMsg(newChatMsg)
        .pipe(take(1))
        .subscribe((res) => {});
    }
  }

  scrollToBottom(): void {
    this.msgListElem.nativeElement.scrollTop =
      this.msgListElem.nativeElement.scrollHeight;
  }
}
