import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BulkEntdata } from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';
import { take } from 'rxjs/operators';
import { LayoutService } from '../../../services/layout.service';

@Component({
  selector: 'app-data-bulk-changes',
  templateUrl: './data-bulk-changes.component.html',
  styleUrls: ['./data-bulk-changes.component.css'],
})
export class DataBulkChangesComponent implements OnInit {
  currentEntItem: BulkEntdata;
  entityCode: string;
  isHandset = this.layoutService.isHandset;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly entityService: EntityService,
    public readonly dialogRef: MatDialogRef<DataBulkChangesComponent>,
    private readonly layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.entityCode = this.data.entityCode;
    this.getEntityItem();
  }

  private getEntityItem() {
    this.entityService
      .geEntityItem(this.entityCode, 'new')
      .pipe(take(1))
      .subscribe((resItem) => {
        this.currentEntItem = resItem as BulkEntdata;
      });
  }

  public getOnlyEditableAttrs(): any[] {
    return this.currentEntItem?.data.filter(
      (x) =>
        x.fld_code != 'label' &&
        x.fld_code != 'tab' &&
        x.fld_code != 'filehistory' &&
        x.fld_code != 'hidden' &&
        x.fld_code != 'productlist' &&
        x.fld_code != 'semiproductlist' &&
        x.fld_code != 'pdfExport' &&
        x.fld_code != 'gallery' &&
        x.fld_code != 'view'
    );
  }

  public normalizeChekboxFlds(data: any[]): any[] {
    for (let attr of data) {
      if (attr.fld_code == 'checkbox') {
        attr.value = (attr.value == 'true' || attr.value == '1') + '';
      }
    }

    return data;
  }

  public save() {
    this.currentEntItem.data = this.normalizeChekboxFlds(
      this.currentEntItem.data
    );
    this.dialogRef.close(this.currentEntItem);
  }
}
