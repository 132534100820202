<mat-dialog-content class="mat-typography">
  <div class="buttonPanel mat-elevation-z3">
    <button mat-button (click)="goBack()">
      <mat-icon>keyboard_backspace</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.back' | translate }}</span>
    </button>
    <mat-divider [vertical]="true"></mat-divider>
  </div>

  <div class="mat-typography" style="overflow: hidden">
    <div class="arrowDiv">
      <div class="leftArrow">
        <button
          mat-flat-button
          color="primary"
          class="navigateBtn"
          *ngIf="currentIndex != 0"
          (click)="goPrevious()"
        >
          Προηγούμενη
        </button>
      </div>
      <div class="rightArrow">
        <button
          mat-flat-button
          color="primary"
          class="navigateBtn"
          *ngIf="currentIndex != photolist.length - 1"
          (click)="goNext()"
        >
          Επόμενη
        </button>
      </div>
    </div>

    <div class="photoDiv">
      <img *ngIf="photo" [src]="photo.fileBase64" class="photoLib" />
    </div>
  </div>
</mat-dialog-content>
