import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stepValue',
  standalone: true,
})
export class StepValuePipe implements PipeTransform {
  transform(stepValue: string, type: 'Started' | 'Completed'): string {
    if (!stepValue) {
      return '';
    }
    const segments = stepValue.split('|');
    if (type === 'Started' && segments.length > 1) {
      return segments[1].trim();
    } else if (type === 'Completed' && segments.length > 3) {
      return segments[3].trim();
    }
    return stepValue;
  }
}
