import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldRowSpan',
  standalone: true,
})
export class FieldRowSpanPipe implements PipeTransform {
  transform(field: any): number {
    if (field.rows != 0) {
      return field.rows;
    }
    if (
      [
        'productlist',
        'prodrecipelist',
        'installationslist',
        'expense_list',
        'purchasingorder_lines',
        'notes_list',
      ].includes(field.fld_code)
    ) {
      return 48;
    } else if (
      field.fld_code === 'gallery' ||
      field.fld_code === 'file_collection'
    ) {
      return 12;
    } else if (['filehistory', 'view'].includes(field.fld_code)) {
      return 5;
    } else if (
      ['semiproductlist', 'team', 'thumbnail'].includes(field.fld_code)
    ) {
      return 3;
    } else {
      return 1;
    }
  }
}
