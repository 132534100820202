<div class="buttonPanel mat-elevation-z3" *ngIf="SHOWTOOLBAR">
  <span *ngIf="SHOWHBACKBTN">
    <button mat-button (click)="goBack()">
      <mat-icon>keyboard_backspace</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.back' | translate }}</span>
    </button>
  </span>
  <mat-divider *ngIf="SHOWHBACKBTN" [vertical]="true"></mat-divider>
  <span *ngIf="extractedData?.length > 0 && !isDialog">
    <button mat-button (click)="goToPrevious()">
      <mat-icon>chevron_left</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.previous' | translate }}</span>
    </button>
  </span>
  <mat-divider
    *ngIf="extractedData?.length > 0 && !isDialog"
    [vertical]="true"
  ></mat-divider>
  <span *ngIf="extractedData?.length > 0 && !isDialog">
    <button mat-button (click)="goToNext()">
      <mat-icon>chevron_right</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.next' | translate }}</span>
    </button>
  </span>
  <mat-divider
    *ngIf="extractedData?.length > 0 && !isDialog"
    [vertical]="true"
  ></mat-divider>

  <span *ngIf="showSaveButton">
    <button mat-button (click)="save()">
      <mat-icon>save</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.save' | translate }}</span>
    </button>
  </span>
  <mat-divider [vertical]="true"></mat-divider>

  <span
    *ngIf="
      itemIdToOpen != 'new' && currentEntItem?.id && entityToOpen != 'projects'
    "
  >
    <button mat-button (click)="copy()">
      <mat-icon>content_copy</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.copy' | translate }}</span>
    </button>
  </span>
  <mat-divider
    *ngIf="itemIdToOpen != 'new' && currentEntItem?.id"
    [vertical]="true"
  ></mat-divider>

  <span
    *ngIf="itemIdToOpen != 'new' && currentEntItem && !currentEntItem.follow"
  >
    <button mat-button (click)="followItem()">
      <mat-icon>notifications_active</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.follow' | translate }}</span>
    </button>
  </span>
  <mat-divider
    *ngIf="itemIdToOpen != 'new' && currentEntItem && !currentEntItem.follow"
    [vertical]="true"
  >
  </mat-divider>

  <span
    *ngIf="itemIdToOpen != 'new' && currentEntItem && currentEntItem.follow"
  >
    <button mat-button (click)="unfollowItem()">
      <mat-icon>notifications_off</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.unfollow' | translate }}</span>
    </button>
  </span>
  <mat-divider
    *ngIf="itemIdToOpen != 'new' && currentEntItem && currentEntItem.follow"
    [vertical]="true"
  >
  </mat-divider>

  <span *ngIf="itemIdToOpen != 'new' && currentEntItem">
    <button mat-button (click)="add_question_diag()">
      <mat-icon>help_outline</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.addQuestion' | translate }}</span>
    </button>
  </span>
  <mat-divider
    *ngIf="itemIdToOpen != 'new' && currentEntItem"
    [vertical]="true"
  >
  </mat-divider>

  <span *ngIf="itemIdToOpen != 'new' && currentEntItem && canTransformToErp">
    <button mat-button (click)="transform_to_Erp()">
      <mat-icon>transform</mat-icon>
      <span *ngIf="!isHandset()">{{
        'Generic.transformation' | translate
      }}</span>
    </button>
  </span>
  <mat-divider
    *ngIf="itemIdToOpen != 'new' && currentEntItem"
    [vertical]="true"
  >
  </mat-divider>

  <span *ngFor="let addBtn of ADDBUTTONS">
    <span *ngIf="itemIdToOpen != 'new' && currentEntItem">
      <button mat-button (click)="sendAdditionalAction(addBtn)">
        <mat-icon>{{ addBtn.icon }}</mat-icon>
        <span *ngIf="!isHandset()">{{ addBtn.text }}</span>
      </button>
    </span>
    <mat-divider
      *ngIf="itemIdToOpen != 'new' && currentEntItem"
      [vertical]="true"
    >
    </mat-divider>
  </span>

  <span
    *ngIf="
      itemIdToOpen != 'new' &&
      currentEntItem &&
      currentEntItem.owner &&
      this.currentUser.id == currentEntItem.owner.id &&
      currentEntItem.isActive == 1
    "
  >
    <button mat-button color="warn" (click)="deactivate()">
      <mat-icon>update_disabled</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.deactivation' | translate }}</span>
    </button>
  </span>
  <mat-divider
    *ngIf="
      itemIdToOpen != 'new' &&
      currentEntItem &&
      currentEntItem.owner &&
      this.currentUser.id == currentEntItem.owner.id &&
      currentEntItem.isActive == 1
    "
    [vertical]="true"
  ></mat-divider>

  <span
    *ngIf="
      itemIdToOpen != 'new' &&
      currentEntItem &&
      this.currentUser.id == currentEntItem.owner.id &&
      currentEntItem.isActive == 0
    "
  >
    <button mat-button color="primary" (click)="activate()">
      <mat-icon>update</mat-icon>
      <span *ngIf="!isHandset()">{{ 'Generic.activation' | translate }}</span>
    </button>
  </span>
  <mat-divider
    *ngIf="
      itemIdToOpen != 'new' &&
      currentEntItem &&
      this.currentUser.id == currentEntItem.owner.id &&
      currentEntItem.isActive == 0
    "
    [vertical]="true"
  ></mat-divider>
</div>
<div class="fieldArea">
  <ng-container
    [ngTemplateOutlet]="firstFldGroup"
    *ngIf="tabFields.length == 0"
  >
  </ng-container>

  <mat-tab-group *ngIf="tabFields.length != 0" class="tab-group">
    <mat-tab>
      <ng-template mat-tab-label>
        <span
          class="mat-tab-badge"
          matBadge="?"
          [matBadgeHidden]="!checkForQuestions(basicFields.fields)"
          matBadgeColor="warn"
          >{{ translateService.instant('Generic.baseData') }}</span
        >
      </ng-template>
      <ng-container [ngTemplateOutlet]="firstFldGroup"> </ng-container>
    </mat-tab>
    <ng-container *ngFor="let tab of tabFields; let tabIndex = index">
      <mat-tab *ngIf="shouldShowTab(tab)">
        <ng-template mat-tab-label>
          <span
            class="mat-tab-badge"
            matBadge="?"
            [matBadgeHidden]="!checkForQuestions(tab.fields)"
            matBadgeColor="warn"
            >{{ tab.title }}</span
          >
        </ng-template>
        <mat-grid-list
          [cols]="isHandset() ? 1 : 4"
          *ngIf="tab.fields.length !== 0"
          rowHeight="100px"
        >
          <mat-grid-tile
            *ngFor="let attr of tab.fields"
            [colspan]="isHandset() ? 1 : (attr | fieldColumnSpan)"
            [rowspan]="attr | fieldRowSpan"
            style="padding-top: 5px"
          >
            <app-fields
              [DISABLED]="isDisabled(attr)"
              [allAttr]="currentEntItem.data"
              [attr]="attr"
              [ITEMID]="itemIdToOpen"
              [OWNER]="currentEntItem.owner"
              [entCode]="ENTITYCOMP"
              [ngStyle]="{
                width: attr.fld_code === 'productlist' ? '98%' : '95%'
              }"
              [showMandatoryErrors]="showMandatoryErrors"
              [showInvalidEndDate]="showInvalidEndDate"
            >
            </app-fields>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-stepper
          *ngIf="tab.steps.length !== 0"
          [selectedIndex]="tab.activeStep"
          linear
          orientation="vertical"
        >
          <mat-step
            *ngFor="let step of tab.steps; let i = index"
            [editable]="true"
            [optional]="false"
            [completed]="step.value !== null"
          >
            <ng-template matStepLabel>
              <span style="display: flex">
                <span>{{ step.title }}</span>
                <mat-error
                  *ngIf="
                    step.value !== null && step.value.startsWith('Started')
                  "
                  style="margin-left: 10px; font-weight: bold"
                >
                  {{ 'Opportunity.start' | translate }} -
                  {{ step.value | stepValue: 'Started' }}
                </mat-error>
                <mat-error
                  *ngIf="
                    step.value !== null && step.value.includes('Completed')
                  "
                  style="margin-left: 10px; font-weight: bold"
                >
                  - {{ 'Opportunity.completed' | translate }} -
                  {{ step.value | stepValue: 'Completed' }}</mat-error
                >
              </span>
            </ng-template>
            <mat-divider></mat-divider>
            <div style="display: flex">
              <button
                mat-flat-button
                *ngIf="step.value === null && i != 0"
                (click)="unlockPrevStep(tabIndex)"
                color="warn"
                class="btnStepper"
                [disabled]="this.currentEntItem?.isActive == 0"
              >
                <mat-icon>lock_open</mat-icon>
                <span *ngIf="!isHandset()">
                  {{ 'Opportunity.unlockprev' | translate }}
                </span>
              </button>
              <button
                mat-flat-button
                *ngIf="step.value === null"
                color="primary"
                class="btnStepper"
                (click)="startStep(step)"
                [disabled]="currentEntItem?.isActive == 0"
              >
                <mat-icon>play_arrow</mat-icon>
                <span *ngIf="!isHandset()">
                  {{ 'Opportunity.start' | translate }}
                </span>
              </button>
              <button
                mat-flat-button
                *ngIf="
                  step.value !== null &&
                  step.value.startsWith('Started') &&
                  !step.value.includes('Completed')
                "
                (click)="completeStep(step)"
                color="primary"
                class="btnStepper"
                [disabled]="this.currentEntItem?.isActive == 0"
              >
                <mat-icon>lock</mat-icon>
                <span *ngIf="!isHandset()">
                  {{ 'Opportunity.completeStep' | translate }}
                </span>
              </button>
              <button
                mat-flat-button
                *ngIf="
                  step.value !== null &&
                  i != tab.steps.length - 1 &&
                  step.value.includes('Completed')
                "
                matStepperNext
                color="primary"
                class="btnStepper"
                (click)="updateActiveStep(tab)"
              >
                <mat-icon>trending_flat</mat-icon>
                <span *ngIf="!isHandset()">
                  {{ 'Opportunity.lockAndProceed' | translate }}
                </span>
              </button>
            </div>
            <mat-grid-list [cols]="isHandset() ? 1 : 4" rowHeight="100px">
              <mat-grid-tile
                *ngFor="let attr of step.fields"
                [colspan]="isHandset() ? 1 : (attr | fieldColumnSpan)"
                [rowspan]="attr | fieldRowSpan"
              >
                <app-fields
                  [DISABLED]="step.value && step.value.includes('Completed')"
                  [allAttr]="currentEntItem.data"
                  [attr]="attr"
                  [ITEMID]="itemIdToOpen"
                  [OWNER]="currentEntItem.owner"
                  [entCode]="ENTITYCOMP"
                  [ngStyle]="{
                    width: attr.fld_code === 'productlist' ? '98%' : '95%'
                  }"
                  [showMandatoryErrors]="showMandatoryErrors"
                  [showInvalidEndDate]="showInvalidEndDate"
                >
                </app-fields>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-divider></mat-divider>
          </mat-step>
        </mat-stepper>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>

<ng-template #firstFldGroup>
  <div class="itemArea" [class.itemArea-M]="isHandset()">
    <div
      class="itemFieldsArea"
      [class.itemFieldsArea-Width]="!isHandset()"
      [class.itemFieldsArea-Width-M]="isHandset()"
    >
      <div *ngIf="currentEntItem">
        <mat-card *ngIf="currentEntItem.id">
          <span class="itemId"
            >{{ entityToOpen[0].toUpperCase() + entityToOpen.slice(1) }}:{{
              currentEntItem.id
            }}</span
          >
          <span class="itemInfos">{{
            currentEntItem.created_at | date: 'd/M/yy, h:mm a'
          }}</span>
        </mat-card>

        <mat-card *ngIf="currentEntItem.id" style="margin-top: 5px">
          <span class="itemId"> Owner </span>
          <span style="display: flex; margin-top: 10px; align-items: center">
            <app-show-avatar [USER]="currentEntItem.owner"></app-show-avatar>
            <span
              >{{ currentEntItem.owner.firstname }}
              {{ currentEntItem.owner.lastname }} (&#64;{{
                currentEntItem.owner.nickname
              }})
            </span>
            <button
              mat-icon-button
              (click)="selectOwnerDiag()"
              *ngIf="
                currentEntItem.owner.id == currentUser.id &&
                currentEntItem.isActive != 0
              "
            >
              <mat-icon color="primary">person_search</mat-icon>
            </button>
          </span>
        </mat-card>

        <mat-card style="margin-top: 5px; max-width: 100%">
          <section
            *ngFor="let attr of basicFields.fields"
            style="min-height: 40px"
          >
            <app-fields
              [DISABLED]="isDisabled(attr)"
              [allAttr]="currentEntItem.data"
              [attr]="attr"
              [ITEMID]="itemIdToOpen"
              [OWNER]="currentEntItem.owner"
              [entCode]="ENTITYCOMP"
              [showMandatoryErrors]="showMandatoryErrors"
              [showInvalidEndDate]="showInvalidEndDate"
            >
            </app-fields>
          </section>
          <section *ngIf="basicFields.steps.length !== 0">
            <mat-stepper
              *ngIf="basicFields.steps.length !== 0"
              [selectedIndex]="basicFields.activeStep"
              linear
              orientation="vertical"
            >
              <mat-step
                *ngFor="let step of basicFields.steps; let i = index"
                [editable]="true"
                [optional]="false"
                [completed]="step.value !== null"
              >
                <ng-template matStepLabel>
                  <span style="display: flex">
                    <span>{{ step.title }}</span>
                    <mat-error
                      *ngIf="
                        step.value !== null && step.value.startsWith('Started')
                      "
                      style="margin-left: 10px; font-weight: bold"
                    >
                      {{ 'Opportunity.start' | translate }} -
                      {{ step.value | stepValue: 'Started' }}
                    </mat-error>
                    <P> - </P>
                    <mat-error
                      *ngIf="
                        step.value !== null && step.value.includes('Completed')
                      "
                      style="margin-left: 10px; font-weight: bold"
                    >
                      {{ 'Opportunity.completed' | translate }} -
                      {{ step.value | stepValue: 'Completed' }}</mat-error
                    >
                  </span>
                </ng-template>
                <mat-divider></mat-divider>
                <div style="display: flex">
                  <button
                    mat-flat-button
                    *ngIf="step.value === null && i != 0"
                    (click)="unlockPrevStep(basicFields)"
                    color="warn"
                    class="btnStepper"
                    [disabled]="this.currentEntItem?.isActive == 0"
                  >
                    <mat-icon>lock_open</mat-icon>
                    <span *ngIf="!isHandset()">
                      {{ 'Opportunity.unlockprev' | translate }}
                    </span>
                  </button>
                  <button
                    mat-flat-button
                    *ngIf="step.value === null"
                    color="primary"
                    class="btnStepper"
                    (click)="startStep(step)"
                    [disabled]="currentEntItem?.isActive == 0"
                  >
                    <mat-icon>play_arrow</mat-icon>
                    <span *ngIf="!isHandset()">
                      {{ 'Opportunity.start' | translate }}
                    </span>
                  </button>
                  <button
                    mat-flat-button
                    *ngIf="step.value === null"
                    (click)="completeStep(step)"
                    color="primary"
                    class="btnStepper"
                    [disabled]="this.currentEntItem?.isActive == 0"
                  >
                    <mat-icon>lock</mat-icon>
                    <span *ngIf="!isHandset()">
                      {{ 'Opportunity.completeStep' | translate }}
                    </span>
                  </button>
                  <button
                    mat-flat-button
                    *ngIf="
                      step.value !== null && i != basicFields.steps.length - 1
                    "
                    matStepperNext
                    color="primary"
                    class="btnStepper"
                  >
                    <mat-icon>trending_flat</mat-icon>
                    <span *ngIf="!isHandset()">
                      {{ 'Opportunity.lockAndProceed' | translate }}
                    </span>
                  </button>
                </div>
                <mat-grid-list [cols]="isHandset() ? 1 : 4" rowHeight="100px">
                  <mat-grid-tile
                    *ngFor="let attr of step.fields"
                    [colspan]="isHandset() ? 1 : (attr | fieldColumnSpan)"
                    [rowspan]="attr | fieldRowSpan"
                  >
                    <app-fields
                      [DISABLED]="
                        step.value && step.value.includes('Completed')
                      "
                      [allAttr]="currentEntItem.data"
                      [attr]="attr"
                      [ITEMID]="itemIdToOpen"
                      [OWNER]="currentEntItem.owner"
                      [entCode]="ENTITYCOMP"
                      [ngStyle]="{
                        width: attr.fld_code === 'productlist' ? '98%' : '95%'
                      }"
                      [showMandatoryErrors]="showMandatoryErrors"
                      [showInvalidEndDate]="showInvalidEndDate"
                    >
                    </app-fields>
                  </mat-grid-tile>
                </mat-grid-list>
                <mat-divider></mat-divider>
              </mat-step>
            </mat-stepper>
          </section>
        </mat-card>
      </div>
    </div>
    <div
      class="postArea"
      [class.postArea-width]="!isHandset()"
      [class.postArea-width-m]="isHandset()"
      *ngIf="itemIdToOpen != 'new'"
    >
      <mat-card style="margin-top: 10px">
        <mat-card-subtitle>{{ 'Generic.notes' | translate }}</mat-card-subtitle>
        <app-data-post
          *ngIf="itemIdToOpen"
          [ITEMID]="itemIdToOpen"
          [disabled]="currentEntItem && currentEntItem.isActive == 0"
        ></app-data-post>
      </mat-card>
      <mat-card style="margin-top: 10px">
        <mat-card-subtitle>{{
          'Generic.approvals' | translate
        }}</mat-card-subtitle>
        <app-data-item-approvals
          [ITEMID]="itemIdToOpen"
          [ENTITYCODE]="entityToOpen"
        ></app-data-item-approvals>
      </mat-card>
    </div>
    <div
      class="linkEntiyArea"
      [class.linkEntiyArea-width]="!isHandset()"
      [class.linkEntiyArea-width-m]="isHandset()"
      *ngIf="itemIdToOpen != 'new'"
    >
      <app-data-connected-list
        [currentEntItem]="currentEntItem"
        [ITEMID]="itemIdToOpen"
      ></app-data-connected-list>
    </div>
  </div>
</ng-template>
