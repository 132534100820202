<div class="GridDiv">
  <button mat-button [matMenuTriggerFor]="columns">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-table
    [dataSource]="lines"
    [class.prodTable-M]="isHandset()"
    class="expense-list-table"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    cdkDropListOrientation="horizontal"
  >
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef cdkDrag> # </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.expense"> </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expense_name">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'ExpenseLines.expense_name' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.expense">
          #{{ element.expense.id }} {{ element.expense.expense_name }}
        </span>

        <span *ngIf="!element.expense && !disabled">
          <app-entity-selector-autocomplete
            ENTITY="expense"
            (selectEntityItem)="fillExpenselineItem($event, element)"
          >
          </app-entity-selector-autocomplete>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef cdkDrag>{{
        'ExpenseLines.date' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-date
          [date]="element.date"
          [disabled]="disabled || !element.expense"
          (dateToString)="setDate($event, element)"
        ></app-date>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'ExpenseLines.amount' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="element.amount"
            type="number"
            [disabled]="disabled || !element.expense"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="pay_method">
      <mat-header-cell *matHeaderCellDef cdkDrag>{{
        'ExpenseLines.payMethod' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <mat-select
            [(ngModel)]="element.pay_method"
            [disabled]="disabled || !element.expense"
          >
            <mat-option value="card">{{
              'ExpenseLines.creditCard' | translate
            }}</mat-option>
            <mat-option value="cash">{{
              'ExpenseLines.cash' | translate
            }}</mat-option>
            <mat-option value="other">{{
              'ExpenseLines.other' | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extranumeric1">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('expenseConfNumFld1')[0]"
          >{{
            settingsService.getSavedSettings('expenseConfNumFld1')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('expenseConfNumFld1')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}1
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <input
            matInput
            [(ngModel)]="element.numFld1"
            type="number"
            [disabled]="disabled || !element.expense"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extranumeric2">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('expenseConfNumFld2')[0]"
          >{{
            settingsService.getSavedSettings('expenseConfNumFld2')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('expenseConfNumFld2')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}2
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <input
            matInput
            [(ngModel)]="element.numFld2"
            type="number"
            [disabled]="disabled || !element.expense"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extranumeric3">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('expenseConfNumFld3')[0]"
          >{{
            settingsService.getSavedSettings('expenseConfNumFld3')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('expenseConfNumFld3')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}3
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <input
            matInput
            [(ngModel)]="element.numFld3"
            type="number"
            [disabled]="disabled || !element.expense"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extrastr1">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('expenseConfStrFld1')[0]"
          >{{
            settingsService.getSavedSettings('expenseConfStrFld1')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('expenseConfStrFld1')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}4
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <textarea
            matInput
            cdkTextareaAutosize
            [(ngModel)]="element.strFld1"
            type="text"
            [disabled]="disabled || !element.expense"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extrastr2">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('expenseConfStrFld2')[0]"
          >{{
            settingsService.getSavedSettings('expenseConfStrFld2')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('expenseConfStrFld2')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}5</span
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <textarea
            matInput
            cdkTextareaAutosize
            [(ngModel)]="element.strFld2"
            type="text"
            [disabled]="disabled || !element.expense"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extrastr3">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span *ngIf="settingsService.getSavedSettings('expenseConfStrFld3')[0]"
          >{{
            settingsService.getSavedSettings('expenseConfStrFld3')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('expenseConfStrFld3')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}6</span
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <textarea
            matInput
            cdkTextareaAutosize
            [(ngModel)]="element.strFld3"
            type="text"
            [disabled]="disabled || !element.expense"
          ></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extraDrpDown1">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span
          *ngIf="settingsService.getSavedSettings('expenseConfDrpDownFld1')[0]"
          >{{
            settingsService.getSavedSettings('expenseConfDrpDownFld1')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('expenseConfDrpDownFld1')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}7</span
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <mat-select
            [(ngModel)]="element.drpDownFld1"
            class="full-width"
            [disabled]="disabled || !element.expense"
          >
            <mat-option
              *ngFor="
                let option of settingsService.getSavedSettings(
                  'expenseConfDrpDownValues1'
                )
              "
              [value]="option.value"
            >
              {{ option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extraDrpDown2">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span
          *ngIf="settingsService.getSavedSettings('expenseConfDrpDownFld2')[0]"
          >{{
            settingsService.getSavedSettings('expenseConfDrpDownFld2')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('expenseConfDrpDownFld2')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}8</span
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <mat-select
            [(ngModel)]="element.drpDownFld2"
            class="full-width"
            [disabled]="disabled || !element.expense"
          >
            <mat-option
              *ngFor="
                let option of settingsService.getSavedSettings(
                  'expenseConfDrpDownValues2'
                )
              "
              [value]="option.value"
            >
              {{ option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extraDrpDown3">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span
          *ngIf="settingsService.getSavedSettings('expenseConfDrpDownFld3')[0]"
          >{{
            settingsService.getSavedSettings('expenseConfDrpDownFld3')[0]?.value
          }}
        </span>
        <span
          *ngIf="!settingsService.getSavedSettings('expenseConfDrpDownFld3')[0]"
        >
          {{ 'ProdConfigurator.extrFld' | translate }}9</span
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="full-width">
          <mat-select
            [(ngModel)]="element.drpDownFld3"
            class="full-width"
            [disabled]="disabled || !element.expense"
          >
            <mat-option
              *ngFor="
                let option of settingsService.getSavedSettings(
                  'expenseConfDrpDownValues3'
                )
              "
              [value]="option.value"
            >
              {{ option.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="del">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'PurchasingOrder.del' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <button
          mat-icon-button
          color="warn"
          (click)="removeItemLine(i)"
          [disabled]="disabled"
          *ngIf="element.expense"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="selectedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: selectedColumns"></mat-row>
  </mat-table>
</div>

<mat-menu #columns="matMenu">
  <mat-selection-list
    [ngModel]="selectedColumns"
    (ngModelChange)="updateSelectedColumns($event)"
    style="max-height: 70vh"
  >
    <mat-list-option
      color="primary"
      *ngFor="let column of gridCols"
      [value]="column.key"
      (click)="$event.stopPropagation()"
    >
      {{ column.desc }}
    </mat-list-option>
  </mat-selection-list>
</mat-menu>
