import { LoginService } from '../../../services/login.service';
import { EntityService } from '../../../services/entity.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Field } from 'src/app/model';
import { LayoutService } from '../../../services/layout.service';

@Component({
  selector: 'app-field-selector',
  templateUrl: './field-selector.component.html',
  styleUrls: ['./field-selector.component.css'],
})
export class FieldSelectorComponent implements OnInit {
  entityCode: string;
  fields: Field[] = [];
  displayedColumns: string[] = ['id', 'name', 'type', 'code'];
  isHandset = this.layoutService.isHandset;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly entityService: EntityService,
    private readonly loginService: LoginService,
    private readonly layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.entityCode = this.data.entity;
    this.getFields();
  }

  private getFields() {
    this.entityService
      .getFldsEntityByName(this.entityCode)
      .pipe(take(1))
      .subscribe((res) => {
        this.fields = res.fields;
      });
  }

  public fldNameTranslation(field: Field): string {
    let lang = this.loginService.getLoginUser().lang;
    return lang == 'en' ? field.label_en : field.label_gr;
  }

  public fldTypeTranslation(field: Field): string {
    let lang = this.loginService.getLoginUser().lang;
    return lang == 'en' ? field.field_specs.name_en : field.field_specs.name_gr;
  }
}
