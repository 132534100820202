<div class="buttonPanel mat-elevation-z3">
  <button mat-button [mat-dialog-close]="null">
    <mat-icon>keyboard_backspace</mat-icon>
    <span *ngIf="!isHandset()">{{ 'Generic.back' | translate }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button mat-button [mat-dialog-close]="selectedModules">
    <mat-icon>save</mat-icon>
    <span *ngIf="!isHandset()">{{ 'Generic.save' | translate }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
</div>
<br />
<div mat-dialog-content style="height: 400px">
  <mat-selection-list [(ngModel)]="selectedModules" color="primary">
    <mat-list-option *ngFor="let module of modules" [value]="module">
      <mat-icon matListItemIcon style="color: black">{{
        module.maticon
      }}</mat-icon>
      <div matListItemTitle>{{ getTraslsatedModuleName(module) }}</div>
    </mat-list-option>
  </mat-selection-list>
</div>
