import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CameraCaptureComponent } from 'src/app/common/camera-capture/camera-capture.component';
import { User } from 'src/app/model';
import { Photo } from 'src/app/models/image';
import { ImageService } from 'src/app/services/image.service';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GalleryCaruselComponent } from './gallery-carusel/gallery-carusel.component';
import { LayoutService } from '../../../../../services/layout.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-gallery-field',
  templateUrl: './gallery-field.component.html',
  styleUrls: ['./gallery-field.component.css'],
})
export class GalleryFieldComponent implements OnInit {
  photos: Photo[] = [];
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  @Input() OWNER: User;
  isHandset = this.layoutService.isHandset;

  constructor(
    public readonly dialog: MatDialog,
    private readonly imageService: ImageService,
    private readonly notificationService: NotificationService,
    public readonly loginService: LoginService,
    private readonly layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    if (this.ITEMID != 'new') {
      this.getPhotos();
    }
  }

  getPhotos() {
    this.imageService
      .getImageList(this.ITEM.id, this.ITEMID)
      .pipe(take(1))
      .subscribe((img) => {
        this.photos = img;
      });
  }

  public del(photo: Photo, index: number) {
    this.imageService
      .delImage(photo.id)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.deleteSuccessful'
          );
          this.photos.splice(index, 1);
        } else {
          this.notificationService.showSnackbarMessage('Messages.deleteFail');
        }
      });
  }

  public openPhoto(photo: Photo, index: number) {
    if (this.isHandset()) {
      return;
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      photo: photo,
      photolist: this.photos,
      index: index,
    };

    const dialogRef = this.dialog.open(GalleryCaruselComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {});
    /**/
  }

  public takePhotoDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {};

    const dialogRef = this.dialog.open(CameraCaptureComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let newPhoto: Photo = new Photo();
        newPhoto.fileBase64 = result;
        newPhoto.entdata_id = this.ITEMID;
        newPhoto.entfield_id = this.ITEM.id;

        this.imageService
          .addImage(newPhoto)
          .pipe(take(1))
          .subscribe((res) => {
            if (res.success) {
              newPhoto.fileBase64 = null;
              this.photos.push(newPhoto);

              this.notificationService.showSnackbarMessage(
                'Messages.successfulSave'
              );
              newPhoto.id = res.data.id;
            } else {
              this.notificationService.showSnackbarMessage('Messages.failSave');
            }
          });
      }
    });
  }

  public downloadImage(image_id: number) {
    this.imageService
      .downloadImage(image_id)
      .pipe(take(1))
      .subscribe((file) => {
        window.open(window.URL.createObjectURL(file));
      });
  }

  public rotateImage(image: Photo) {
    this.imageService
      .rotateImage(image.id)
      .pipe(take(1))
      .subscribe((res) => {
        image.fileBase64 = res.data;
      });
  }
}
