<div [class.roomCardM]="isHandset()" [class.roomCard]="!isHandset()">
  <mat-card
    class="area"
    [class.areaM]="isHandset()"
    [class.area]="!isHandset()"
  >
    <mat-spinner
      *ngIf="showLoader"
      style="left: 50%; margin-top: 25%; margin-left: -4em; position: absolute"
    >
    </mat-spinner>
    <div #msgList class="conversation-history listForModule">
      <mat-list>
        <mat-list-item
          style="margin: 10px; margin-bottom: 25px; height: fit-content"
          *ngFor="let chat of channelHistory"
          class="test"
          [dir]="isMessageMine(chat) ? 'rtl' : 'ltr'"
          [class.ismineMsg]="!isMessageMine(chat) && !isHandset()"
          [class.ismineMsgM]="!isMessageMine(chat) && isHandset()"
        >
          <div matListItemAvatar *ngIf="!isMessageMine(chat)">
            <app-show-avatar [USER]="chat.user"></app-show-avatar>
          </div>
          <span matListItemTitle style="color: grey">
            <span style="font-size: 10px" *ngIf="!isMessageMine(chat)"
              ><strong>{{
                chat.user.firstname + ' ' + chat.user.lastname
              }}</strong>
            </span>
            <span style="font-size: 8px">
              {{ chat.created_at | date: 'medium' }}
            </span>
            <span class="totalReactions">
              <span
                *ngFor="let det of chat.details"
                [matTooltip]="det.user.firstname + ' ' + det.user.lastname"
                matTooltipPosition="above"
              >
                <span *ngIf="det.reaction == 1" class="totalReactionIcon"
                  >🧡
                </span>
                <span *ngIf="det.reaction == 4" class="totalReactionIcon"
                  >👍
                </span>
                <span *ngIf="det.reaction == 2" class="totalReactionIcon"
                  >😀</span
                >
                <span *ngIf="det.reaction == 3" class="totalReactionIcon"
                  >❓</span
                >
              </span>
            </span>
          </span>
          <p
            matListItemLine
            class="msg"
            style="margin-top: 5px"
            [class.outcomingMsg]="isMessageMine(chat)"
            [class.incomingMsg]="!isMessageMine(chat)"
            [dir]="'ltr'"
          >
            <span *ngIf="chat.type == 'text'" ItemLink
              ><span>{{ chat.message }}</span>
            </span>
            <span *ngIf="chat.type == 'file'">
              <div class="fileViewDiv">
                <div>
                  <mat-icon color="primary">file_present</mat-icon>
                </div>
                <div>{{ chat.message }}</div>
                <div style="margin-top: -10px">
                  <button
                    mat-icon-button
                    (click)="downloadAttachment(chat)"
                    color="primary"
                  >
                    <mat-icon>file_download</mat-icon>
                  </button>
                </div>
              </div>
            </span>
          </p>

          <button
            mat-icon-button
            class="noReactionBtn"
            *ngIf="!isMessageMine(chat)"
            (click)="setReaction(chat, 1)"
            (mouseover)="openReactions(chat)"
          >
            <div class="reactionToolbar" *ngIf="chat.showReactions">
              <span
                class="favorite_btn"
                style="margin-left: 21px"
                (click)="$event.stopPropagation(); setReaction(chat, 1)"
                >🧡</span
              >
              <span
                class="favorite_btn"
                (click)="$event.stopPropagation(); setReaction(chat, 4)"
                >👍</span
              >
              <span
                class="favorite_btn"
                (click)="$event.stopPropagation(); setReaction(chat, 2)"
                >😀</span
              >
              <span
                class="favorite_btn"
                (click)="$event.stopPropagation(); setReaction(chat, 3)"
                >❓</span
              >
            </div>

            <mat-icon
              class="reactionBtn text-primary"
              style="color: #2a7189"
              *ngIf="getMyreaction(chat) == 0"
            >
              favorite_border</mat-icon
            >
            <span class="currentEmoji" *ngIf="getMyreaction(chat) == 1"
              >🧡</span
            >
            <span class="currentEmoji" *ngIf="getMyreaction(chat) == 2"
              >😀</span
            >
            <span class="currentEmoji" *ngIf="getMyreaction(chat) == 3"
              >❓</span
            >
            <span class="currentEmoji" *ngIf="getMyreaction(chat) == 4"
              >👍</span
            >
          </button>
        </mat-list-item>
      </mat-list>
    </div>

    <div class="conversation-input">
      <mat-divider></mat-divider>
      <input
        hidden
        (change)="onSelectedFile($event.target.files)"
        #channelfileInput
        type="file"
        name="file"
      />
      <button
        mat-icon-button
        color="primary"
        (click)="channelfileInput.click()"
      >
        <mat-icon>attach_file</mat-icon>
      </button>

      <mat-form-field class="full-width">
        <mat-label>{{ 'Chat.typeMessage' | translate }}</mat-label>
        <input
          matInput
          (keydown.enter)="sendMessage()"
          name="text"
          [(ngModel)]="text"
        />
        <mat-icon color="primary" matSuffix (click)="sendMessage()"
          >send</mat-icon
        >
      </mat-form-field>
    </div>

    <div style="width: 100%; height: 10px">
      <mat-progress-bar
        *ngIf="fileUpload?.status === 'progress'"
        mode="determinate"
        [value]="fileUpload?.message"
      >
      </mat-progress-bar>
    </div>
  </mat-card>
  <div style="width: 30vw" *ngIf="!isHandset()">
    <div style="margin: 10px">
      <app-data-connected-list
        [currentEntItem]="currentItem"
        [ITEMID]="ITEMID"
      ></app-data-connected-list>
    </div>
    <mat-card style="margin: 10px">
      <mat-card-subtitle>{{ 'Generic.files' | translate }}</mat-card-subtitle>
      <mat-list class="chat-files">
        <mat-list-item *ngFor="let file of getOnlyFileMsg()">
          <div matListItemIcon>
            <mat-icon color="primary">file_present</mat-icon>
          </div>
          <span matListItemTitle> {{ file.message }}</span>
          <span matListItemLine>
            <span style="font-size: 10px"
              ><strong>{{
                file.user.firstname + ' ' + file.user.lastname
              }}</strong>
            </span>
            <span style="font-size: 8px">
              - {{ file.created_at | date: 'medium' }}
            </span>
          </span>
          <button
            mat-icon-button
            matListItemMeta
            (click)="downloadAttachment(file)"
            color="primary"
          >
            <mat-icon color="primary">file_download</mat-icon>
          </button>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </mat-card>
  </div>
</div>
