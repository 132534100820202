import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DataListComponent } from '../data-list/data-list.component';
import { Location } from '@angular/common';
import { AdditionalBtn } from 'src/app/model';
import { LayoutService } from '../../../services/layout.service';

@Component({
  selector: 'app-data-list-with-filters',
  templateUrl: './data-list-with-filters.component.html',
  styleUrls: ['./data-list-with-filters.component.css'],
})
export class DataListWithFiltersComponent {
  @Input() entity_code: string;
  @Input() ADDITEMBUTTONS: AdditionalBtn[];
  @Output() ADDITIONALITEMBTNACTION = new EventEmitter<AdditionalBtn>();
  public filterMode: boolean = true;
  public showItem: boolean = false;
  public selectedItem: string;
  @ViewChild(DataListComponent) listComp: DataListComponent;
  isHandset = this.layoutService.isHandset;

  constructor(
    private readonly layoutService: LayoutService,
    private readonly __location: Location
  ) {}

  selectItem(entityItem: any) {
    this.selectedItem = entityItem.id;
    this.showItem = true;
  }

  public closeItem() {
    this.showItem = false;
  }

  public goBack() {
    this.__location.back();
  }
  public search() {
    this.listComp.entityFilters.onlyFollow = 0;
    this.listComp.entityFilters.currentPage = 1;
    this.listComp.refreshEntityList(this.listComp.entityFilters);
  }

  public getOnlyFollow() {
    this.listComp.entityFilters.onlyFollow = 1;
    this.listComp.refreshEntityList(this.listComp.entityFilters);
  }

  public newItem() {
    this.selectedItem = 'new';
    this.showItem = true;
  }

  public clearAllfilters() {
    this.listComp.filtersComp.clearAllfilters();
  }

  public additionalItemAction(ev: AdditionalBtn) {
    this.ADDITIONALITEMBTNACTION.emit(ev);
  }
}
