<div class="buttonPanel mat-elevation-z3" *ngIf="showToolbar">
  <button mat-button (click)="goBack()">
    <mat-icon>keyboard_backspace</mat-icon>
    <span *ngIf="!isHandset()">{{ 'Generic.back' | translate }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button mat-button (click)="goToPath('/localadmin/userlist')">
    <mat-icon>supervisor_account</mat-icon>
    <span *ngIf="!isHandset()">{{ 'Company.users' | translate }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button mat-button (click)="goToPath('/localadmin/company')">
    <mat-icon>account_tree</mat-icon>
    <span *ngIf="!isHandset()">{{ 'Company.structure' | translate }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button mat-button (click)="goToPath('/localadmin/cost-centers')">
    <mat-icon>device_hub</mat-icon>
    <span *ngIf="!isHandset()">{{ 'LocalAdmin.costCenters' | translate }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button mat-button (click)="goToPath('/localadmin/invite')">
    <mat-icon>forward_to_inbox</mat-icon>
    <span *ngIf="!isHandset()">{{
      'LocalAdmin.userIvitation' | translate
    }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
  <button mat-button (click)="goToPath('/localadmin/connectasuser')">
    <mat-icon>login</mat-icon>
    <span *ngIf="!isHandset()">{{
      'LocalAdmin.connectAsUser' | translate
    }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>

  <button mat-button (click)="goToPath('/localadmin/erp')">
    <mat-icon>upload_file </mat-icon>
    <span *ngIf="!isHandset()">{{ 'ERP.erp_settings' | translate }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>

  <button mat-button (click)="goToPath('/localadmin/system-settings')">
    <mat-icon>settings</mat-icon>
    <span *ngIf="!isHandset()">{{
      'LocalAdmin.systemsettings' | translate
    }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
</div>

<div style="height: calc(100% - 55px); margin: 10px; overflow-y: auto">
  <div class="userDepFilter" *ngIf="!isHandset()">
    <mat-form-field>
      <mat-label>{{ 'Company.department' | translate }}</mat-label>
      <mat-select
        matNativeControl
        [(ngModel)]="userFilters.departmentId"
        (ngModelChange)="getUsers()"
      >
        <mat-option [value]="null">--</mat-option>
        <mat-option
          *ngFor="let department of departments"
          [value]="department.id"
        >
          {{ department.title }} - {{ department.location }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <app-grid
    [ID]="'adminUserList'"
    [ELEMENT_DATA]="users"
    [ATTR]="gridColumns"
    [FILTERS]="userFilters"
    [MULTISELECT]="true"
    [SHOWTRASHFILTER]="true"
    (getData)="refreshUsers($event)"
    (ONROWCLICK)="onSelectUser($event)"
    (ONBALKACTIONCLICK)="onBulkActionPress($event)"
  >
  </app-grid>

  <button
    mat-fab
    color="primary"
    class="add-button"
    matTooltip="{{ 'Register.addUser' | translate }}"
    (click)="newUser()"
  >
    <i class="material-icons">add</i>
  </button>
</div>
