import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Signal,
} from '@angular/core';
import { MatCard } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { TranslatePipe } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import {
  MatList,
  MatListItem,
  MatListSubheaderCssMatStyler,
} from '@angular/material/list';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { Entity, SavedFilter } from '../../../../../model';
import { take } from 'rxjs/operators';
import { EntityItemFilter } from '../../../../filters';
import { EntityService } from '../../../../../services/entity.service';
import { NotificationService } from '../../../../../services/notification.service';
import { AsyncPipe } from '@angular/common';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { LayoutService } from '../../../../../services/layout.service';

@Component({
  selector: 'app-saved-filters',
  standalone: true,
  imports: [
    MatCard,
    MatFormField,
    TranslatePipe,
    FormsModule,
    MatListSubheaderCssMatStyler,
    MatInput,
    MatIcon,
    MatIconButton,
    MatList,
    MatListItem,
    AsyncPipe,
    MatLabel,
    MatOption,
    MatSelect,
  ],
  templateUrl: './saved-filters.component.html',
  styleUrl: './saved-filters.component.css',
})
export class SavedFiltersComponent implements OnInit {
  @Input() ENTITY: Entity;
  @Input() FILTERS: EntityItemFilter;
  @Output() applyFilter = new EventEmitter<SavedFilter>();
  @Output() saveFilters = new EventEmitter<string>();

  private readonly entityService = inject(EntityService);
  private readonly notificationService = inject(NotificationService);
  private readonly layoutService = inject(LayoutService);

  newFilterName: string;
  fields: any[] = [];
  savedFilters: Signal<SavedFilter[]>;
  isHandset = this.layoutService.isHandset;

  ngOnInit() {
    this.fields = this.ENTITY.fields.filter((x) => x.hiddeOnFilters == 0);
    this.getSavedFiltersList();
  }

  public getSavedFiltersList() {
    this.savedFilters = this.entityService.getSavedFilters(
      this.ENTITY.entity_code
    );
  }

  public onSaveFilters() {
    this.saveFilters.emit(this.newFilterName);
    this.newFilterName = '';
  }

  public deleteFilter(savedFilter: SavedFilter, event: MouseEvent) {
    event.stopPropagation();
    this.entityService
      .delSavedFilters(savedFilter.id)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.deleteSuccessful'
          );
          this.getSavedFiltersList();
        } else {
          this.notificationService.showSnackbarMessage('Messages.deleteFail');
        }
      });
  }

  applySavedFilter(savedFilter: SavedFilter) {
    this.applyFilter.emit(savedFilter);
  }
}
