import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldColumnSpan',
  standalone: true,
})
export class FieldColumnSpanPipe implements PipeTransform {
  transform(field: any): number {
    if (field.cols !== 0) {
      return field.cols;
    }
    if (field.fld_code === 'label') {
      return field.for_entity && field.for_entity != '' ? +field.for_entity : 4;
    } else if (field.fld_code === 'view') {
      return 2;
    } else if (
      [
        'gallery',
        'purchasingorder_lines',
        'expense_list',
        'thumbnail',
        'file_collection',
      ].includes(field.fld_code)
    ) {
      return 4;
    } else {
      return 1;
    }
  }
}
