<div class="galleryGridDiv">
  <mat-grid-list
    [cols]="isHandset() ? 1 : 4"
    rowHeight="300"
    style="margin: 30px"
  >
    <mat-grid-tile *ngFor="let photo of photos; let i = index">
      <div>
        <div>
          <app-gallery-image
            [photo]="photo"
            (click)="openPhoto(photo, i)"
          ></app-gallery-image>
        </div>
        <div>
          <button
            mat-icon-button
            color="primary"
            class="downloadBtn"
            (click)="rotateImage(photo)"
          >
            <mat-icon>rotate_90_degrees_cw</mat-icon>
          </button>

          <button
            mat-icon-button
            color="primary"
            class="downloadBtn"
            (click)="downloadImage(photo.id)"
          >
            <mat-icon>file_download</mat-icon>
          </button>

          <button
            mat-icon-button
            color="warn"
            (click)="del(photo, i)"
            *ngIf="loginService.getLoginUser().id == OWNER.id"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
<button
  mat-fab
  color="primary"
  class="add-button"
  matTooltip="{{ 'Generic.add' | translate }}"
  (click)="takePhotoDiag()"
  *ngIf="ITEMID != 'new'"
>
  <i class="material-icons">photo_camera</i>
</button>
