import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ERPSpdoctype } from 'src/app/models/erpspdoctype';
import { TransformEntityToErp } from 'src/app/models/tranformentitytoerp';
import { ErpSpdoctypeService } from 'src/app/services/erp/erp-spdoctype.service';
import { TransformationService } from 'src/app/services/transformation.service';
import { LayoutService } from '../../../../services/layout.service';
import { take } from 'rxjs/operators';
import { DataItemDiagComponent } from 'src/app/common/entity/data-item-diag/data-item-diag.component';

@Component({
  selector: 'app-transform-entity-to-erp',
  templateUrl: './transform-entity-to-erp.component.html',
  styleUrls: ['./transform-entity-to-erp.component.css'],
})
export class TransformEntityToErpComponent implements OnInit {
  public spdoctypes: ERPSpdoctype[] = [];
  public idsForTansform: number[] = [];
  public transformEntityToErp: TransformEntityToErp =
    new TransformEntityToErp();
  public responseTransformations: any[] = [];
  public percetange: number = 0;
  public desired_quantity: number;
  public backorderValue: number;
  public showTransformationOptions: boolean = true;
  public selectedTransformationType:
    | 'erp'
    | 'pickingorder'
    | 'receivinglist'
    | null = null;
  isHandset = this.layoutService.isHandset;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<TransformEntityToErpComponent>,
    private readonly doctypeService: ErpSpdoctypeService,
    private readonly transformationService: TransformationService,
    private readonly router: Router,
    private readonly layoutService: LayoutService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.idsForTansform = this.data.entity_ids;
    this.getdocTypes();
    this.desired_quantity = this.data.backorderValue;
    console.log(this.desired_quantity);
    console.log(this.data.backorderValue);
  }

  public showTransformation(type: 'erp' | 'pickingorder' | 'receivinglist') {
    this.showTransformationOptions = false;
    this.selectedTransformationType = type;
    if (type === 'pickingorder') {
      for (let entdata_id of this.idsForTansform) {
        this.transformOrdertoPicking(entdata_id);
      }
      this.dialogRef.close();
    } else if (type === 'receivinglist') {
      for (let entdata_id of this.idsForTansform) {
        this.transformPurchasingOrdertoReceiving(entdata_id);
      }
      this.dialogRef.close();
    }
  }

  public getdocTypes() {
    this.doctypeService
      .getActiveSpdoctypes()
      .pipe(take(1))
      .subscribe((res) => {
        if (this.isOrderEntity() || this.isPickingOrderEntity()) {
          this.spdoctypes = res.filter((x) => x.transactor_source == 'account');
        } else if (
          this.isPurchasingOrderEntity() ||
          this.isReceivingListEntity()
        ) {
          this.spdoctypes = res.filter(
            (x) => x.transactor_source == 'supplier'
          );
        } else if (this.isProductionOrderEntity()) {
          this.spdoctypes = res.filter((x) => x.doc_category == 2);
        } else {
          this.spdoctypes = res.filter(
            (x) => x.wh_behavior == 1 || x.wh_behavior == -1
          );
        }
      });
  }

  public selectedSpdocTitle() {
    return (
      this.spdoctypes.find(
        (x) => x.id == this.transformEntityToErp.spdoctype_id
      )?.title ?? ''
    );
  }

  public startTranformation() {
    if (!this.transformEntityToErp.spdoctype_id) {
      alert('Please select a document type before transforming to ERP.');
      return;
    }

    if (this.isProductionOrderEntity() && !this.desired_quantity) {
      alert('Please provide a desired quantity for production orders.');
      return;
    }

    for (let entdata_id of this.idsForTansform) {
      this.transform(entdata_id, this.desired_quantity);
    }
  }

  public isProductionOrderEntity(): boolean {
    return window.location.pathname.includes('/productionorder');
  }

  public isOrderEntity(): boolean {
    return window.location.pathname.includes('/order');
  }

  public isPurchasingOrderEntity(): boolean {
    return window.location.pathname.includes('/purchasingorder');
  }

  public isPickingOrderEntity(): boolean {
    return window.location.pathname.includes('/pickingorder');
  }

  public isReceivingListEntity(): boolean {
    return window.location.pathname.includes('/receivinglist');
  }

  private transform(entdata_id: number, desired_quantity: number) {
    this.transformEntityToErp.entdata_id = entdata_id;
    this.transformEntityToErp.desired_quantity = desired_quantity;

    console.log('Payload:', this.transformEntityToErp); // Debug log

    this.transformationService
      .entityToErp(this.transformEntityToErp)
      .pipe(take(1))
      .subscribe((res) => {
        this.percetange += 100 / this.idsForTansform.length;
        this.responseTransformations.push({
          success: res.success,
          message: !res.success ? res.data : '',
          entity_id: entdata_id,
          doc_id: res.data?.doc_id,
          type: res.data?.type,
        });
      });
  }

  private transformOrdertoPicking(entdata_id: number) {
    this.transformationService
      .orderToPicking(entdata_id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res.success) {
            this.responseTransformations.push({
              success: true,
              entity_id: entdata_id,
            });
            this.previewPickingOrder(res.data);
          } else {
            this.responseTransformations.push({
              success: false,
              entity_id: entdata_id,
              message: res.data || 'An error occurred during transformation.',
            });
          }
        },
        (error) => {
          this.responseTransformations.push({
            success: false,
            entity_id: entdata_id,
            message: 'A network or server error occurred.',
          });
        }
      );
  }

  private transformPurchasingOrdertoReceiving(entdata_id: number) {
    this.transformationService
      .purchasingOrderToReceiving(entdata_id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res.success) {
            this.responseTransformations.push({
              success: true,
              entity_id: entdata_id,
              message: 'Transformation to Picking Order successful.',
            });
            this.previewRecevingList(res.data);
          } else {
            this.responseTransformations.push({
              success: false,
              entity_id: entdata_id,
              message: res.data || 'An error occurred during transformation.',
            });
          }
        },
        (error) => {
          this.responseTransformations.push({
            success: false,
            entity_id: entdata_id,
            message: 'A network or server error occurred.',
          });
        }
      );
  }

  public previewDoc(doc_id, type: string) {
    if (type == 'wh') {
      this.router.navigate(['/erp_document/', doc_id]);
      this.dialogRef.close();
    } else if ((type = 'fin')) {
      this.router.navigate(['/erp_docs/fin/edit/', doc_id]);
      this.dialogRef.close();
    }
  }

  public previewPickingOrder(pickingOrder: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: pickingOrder.id,
      entityCode: 'pickingorder',
    };

    this.dialog.open(DataItemDiagComponent, dialogConfig);
  }

  public previewRecevingList(pickingOrder: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: pickingOrder.id,
      entityCode: 'receivinglist',
    };

    this.dialog.open(DataItemDiagComponent, dialogConfig);
  }
}
