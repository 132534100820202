<div class="GridDiv">
  <mat-table
    [dataSource]="lines"
    [class.prodTable-M]="isHandset()"
    class="product-recipe-list-table"
  >
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product"> </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="product_name">
      <mat-header-cell *matHeaderCellDef>
        {{ 'PurchasingOrder.prodName' | translate }}</mat-header-cell
      >

      <mat-cell *matCellDef="let element">
        <span *ngIf="element.product">
          #{{ element.product.id }} {{ element.product.product_name }}
        </span>

        <span *ngIf="!element.product">
          <app-entity-selector-autocomplete
            ENTITY="product"
            (selectEntityItem)="fillProdlineItem($event, element)"
          >
          </app-entity-selector-autocomplete>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="qty">
      <mat-header-cell *matHeaderCellDef>
        {{ 'PurchasingOrder.qty' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="element.qty"
            type="number"
            [disabled]="disabled || !element.product"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="unit">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        {{ 'QuoteConfigurator.unit' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <select
            [(ngModel)]="element.unit"
            matNativeControl
            required
            [disabled]="disabled || !element.product"
          >
            <option [value]="0">
              {{ 'QuoteConfigurator.piece' | translate }}
            </option>
            <option [value]="1">
              {{ 'QuoteConfigurator.smallBox' | translate }}
            </option>
            <option [value]="2">
              {{ 'QuoteConfigurator.box' | translate }}
            </option>
            <option [value]="3">
              {{ 'QuoteConfigurator.palet' | translate }}
            </option>
            <option [value]="4">
              {{ 'QuoteConfigurator.metre' | translate }}
            </option>
            <option [value]="5">
              {{ 'QuoteConfigurator.kilo' | translate }}
            </option>
          </select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="del">
      <mat-header-cell *matHeaderCellDef>
        {{ 'PurchasingOrder.del' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <button
          mat-icon-button
          color="warn"
          (click)="removeItemLine(i)"
          [disabled]="disabled"
          *ngIf="element.product"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="selectedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: selectedColumns"></mat-row>
  </mat-table>
</div>
