import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Module } from 'src/app/model';
import { CompanyService } from 'src/app/services/company.service';
import { LoginService } from 'src/app/services/login.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LayoutService } from '../../../../services/layout.service';

@Component({
  selector: 'app-permition-bulk-assign',
  templateUrl: './permition-bulk-assign.component.html',
  styleUrls: ['./permition-bulk-assign.component.css'],
})
export class PermitionBulkAssignComponent implements OnInit {
  modules: Module[] = [];
  selectedModules: Module[] = [];
  destroyRef = inject(DestroyRef);
  isHandset = this.layoutService.isHandset;

  constructor(
    private readonly companyService: CompanyService,
    private readonly loginService: LoginService,
    private readonly layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.getModules();
  }

  private getModules() {
    this.companyService.teamworkModules
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resModule) => {
        this.modules = resModule;
      });
  }

  public getTraslsatedModuleName(module: Module): string {
    return this.loginService.getLoginUser().lang == 'en'
      ? module.name
      : module.name_gr;
  }
}
